import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import routes from '../../../utils/routes.json';
import {
  isPermissionAllowed,
  isToFixedNoRound,
  setDateFormat,
} from '../../../utils/Utils';
import dealService from '../../../services/deal.service';
import AddDeal from './AddDeal';
import {
  DEALS_LABEL_BUTTON,
  paginationDefault,
} from '../../../utils/constants';
import {
  changePaginationLimit,
  changePaginationPage,
} from '../../../views/Deals/contacts/utils';
import { ProgressBar } from 'react-bootstrap';
import ButtonIcon from '../../commons/ButtonIcon';
import Table from '../../GenericTable';
import TableSkeleton from '../../commons/TableSkeleton';
import { LOST, WON } from '../../../views/Deals/pipelines/Pipeline.constants';
import userService from '../../../services/user.service';
import TextOverflowTooltip from '../../commons/TextOverflowTooltip';
import MaterialIcon from '../../commons/MaterialIcon';
import Avatar from '../../Avatar';
import TableRowHover from '../../commons/TableRowHover';
import IdfOwnersHeader from '../../idfComponents/idfAdditionalOwners/IdfOwnersHeader';
import Skeleton from 'react-loading-skeleton';
import stageService from '../../../services/stage.service';

function calculateStageProgress(stages, currentStage) {
  const totalStages = stages.length;
  const currentFoundStage = stages.find(
    (stage) => stage.id === currentStage.id
  );
  if (!currentFoundStage) {
    return 0;
  }
  const currentPosition = currentFoundStage.position;
  return Math.round((currentPosition / totalStages) * 100);
}

const StageProgressBar = ({ stages = [], stage = {}, variant = 'primary' }) => {
  // get all the pipeline stages and then dynamic calculate the current stage
  const [perc, setPerc] = useState(0);
  const [loading, setLoading] = useState(false);
  const loadStageProgress = async () => {
    try {
      setLoading(true);
      if (stage?.status === WON || stage.status === LOST) {
        setPerc(100);
      } else {
        setPerc(calculateStageProgress(stages, stage));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadStageProgress();
  }, []);
  return (
    <>
      {loading ? (
        <Skeleton height="10" width={80} />
      ) : (
        <ProgressBar
          now={parseInt(perc)}
          className="w-100 rounded"
          variant={variant}
        />
      )}
    </>
  );
};

const Deals = ({
  contactId,
  moduleMap,
  organizationId,
  organization,
  contactProfile,
}) => {
  const history = useHistory();
  const [openDeal, setOpenDeal] = useState(false);
  const [me, setMe] = useState(null);
  const [stages, setStages] = useState([]);
  const [deals, setDeals] = useState([]);
  const [pagination, setPagination] = useState(paginationDefault);
  const [paginationData, setPaginationData] = useState(paginationDefault);
  const [showLoading, setShowLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    if (organizationId || contactId) {
      getDeals();
      loadStages();
    }
  }, [organizationId, contactId, paginationData]);

  const getDeals = async () => {
    setTableLoading(true);
    const dealsFilter = {};

    if (contactId) dealsFilter.contact_person_id = contactId;
    if (organizationId) dealsFilter.contact_organization_id = organizationId;

    try {
      const { data } = await dealService.getDeals(dealsFilter, {
        page: paginationData.page,
        limit: paginationData.limit,
      });
      setDeals(data.deals);
      setPagination(data.pagination);
    } catch (err) {
      console.log(err);
    } finally {
      setShowLoading(false);
      setTableLoading(false);
    }
  };

  const loadStages = async () => {
    try {
      const data = await stageService.getStages();
      setStages(data || []);
    } catch (e) {
      setStages([]);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  const getCurrentUser = async () => {
    const me = await userService
      .getUserInfo()
      .catch((err) => console.error(err));

    setMe(me);
  };

  const columns = [
    {
      key: 'title',
      component: `${moduleMap.singular} Name`,
    },
    {
      key: 'value',
      component: 'Amount',
    },
    {
      key: 'stages',
      component: 'stage',
      width: '250px',
    },
    {
      key: 'expectedCloseDate',
      component: 'Closing Date',
    },
    {
      key: 'contactPerson',
      component: 'Contact Person',
    },
    {
      key: 'owner',
      component: 'Owners',
    },
  ];

  const rows = deals?.map((item) => {
    const {
      id,
      name,
      amount,
      contact,
      stage,
      date_closed,
      assigned_user,
      owners,
    } = item;

    const isPrincipalOwner =
      me && item
        ? me?.role?.admin_access || assigned_user?.id === me?.id
        : false;

    const response = {
      ...item,
      bgColor: 'hover-actions',
      dataRow: [
        {
          key: 'title',
          component: (
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <Link
                  to={`${routes.dealsPipeline}/${item.id}`}
                  className="text-black text-truncate fw-bold"
                >
                  {name}
                </Link>
              </div>
            </div>
          ),
        },
        {
          key: 'value',
          component: <span>{isToFixedNoRound(amount, 2)}</span>,
        },
        {
          key: 'stages',
          component: (
            <div className="d-flex align-items-center">
              <div className="flex-fill w-20 deal-progress-bar">
                <StageProgressBar
                  variant={
                    item?.status === WON
                      ? 'success'
                      : item?.status === LOST
                      ? 'danger'
                      : 'primary'
                  }
                  stages={stages}
                  stage={
                    item?.status === WON || item?.status === LOST ? item : stage
                  }
                />
              </div>
              <span className="flex align-items-center gap-2 flex-fill w-50 pl-2">
                <TextOverflowTooltip
                  text={
                    item?.status === WON
                      ? 'Closed Won'
                      : item?.status === LOST
                      ? 'Closed Lost'
                      : stage?.name
                  }
                />{' '}
                {item?.status === WON && (
                  <MaterialIcon
                    icon="thumb_up"
                    clazz="text-success position-relative"
                    size="fs-6"
                    style={{ top: 3 }}
                  />
                )}
                {item?.status === LOST && (
                  <MaterialIcon
                    icon="thumb_down"
                    clazz="text-red position-relative"
                    size="fs-6"
                    style={{ top: 4 }}
                  />
                )}
              </span>
            </div>
          ),
        },
        {
          key: 'expectedCloseDate',
          component: (
            <span>
              {date_closed ? setDateFormat(date_closed, 'MM/DD/YYYY') : '--'}
            </span>
          ),
        },
        {
          key: 'contactPerson',
          component: (
            <>
              {contact?.first_name || contact?.last_name ? (
                <div className="d-flex gap-1 align-items-center">
                  <Avatar
                    defaultSize="xs"
                    isMultiple
                    user={contact}
                    hovering=""
                  />
                  <span>
                    {contact?.first_name} {contact?.last_name || ''}
                  </span>
                </div>
              ) : (
                <span> -- </span>
              )}
            </>
          ),
        },
        {
          key: 'owner',
          component: (
            <>
              <TableRowHover
                onClick={() => {
                  history.push(`${routes.dealsPipeline}/${item.id}`);
                }}
              />

              <div className="position-relative z-index-99">
                <IdfOwnersHeader
                  showToolTip
                  mainOwner={assigned_user}
                  service={dealService}
                  serviceId={id}
                  listOwners={owners}
                  isClickable={false}
                  onClick={(e) => {
                    e?.preventDefault();
                    e?.stopPropagation();
                  }}
                  defaultSize="xs"
                  maxOwners={0}
                  isprincipalowner={isPrincipalOwner}
                  small
                  addBtnBgGray
                />
              </div>
            </>
          ),
        },
      ],
    };
    return response;
  });

  return (
    <>
      <div className="py-3">
        <div className="d-flex justify-content-between px-3 mb-2">
          <h4 className="mb-0">{moduleMap?.singular}</h4>

          <div className="ml-auto">
            <AddDeal
              organizationId={organizationId}
              setOpenDeal={setOpenDeal}
              organization={organization}
              openDeal={openDeal}
              contactProfile={contactProfile}
              onGetDeals={getDeals}
            >
              {isPermissionAllowed('deals', 'create') && (
                <ButtonIcon
                  icon="add"
                  classnames="btn-sm border-0"
                  label={DEALS_LABEL_BUTTON.replace(
                    /Pipeline/g,
                    moduleMap?.singular
                  )}
                  onClick={() => setOpenDeal(true)}
                />
              )}
            </AddDeal>
          </div>
        </div>

        <div className="org-deal-table table-responsive-md">
          {showLoading ? (
            <TableSkeleton cols={6} rows={6} />
          ) : (
            <div>
              <Table
                stickyColumn="opportunity-tab-table"
                columns={columns}
                data={rows}
                headClass="bg-gray-table-head border-top"
                usePagination
                paginationInfo={pagination}
                onPageChange={(newPage) =>
                  changePaginationPage(newPage, setPaginationData)
                }
                onLimitChange={(perPage) =>
                  changePaginationLimit(perPage, setPaginationData)
                }
                showPerPage={true}
                emptyDataText="No opportunities available yet"
                title="Opportunities"
                dataInDB={pagination?.totalPages > 1}
                noDataInDbValidation={true}
                stickyFooter={false}
                tableLoading={tableLoading}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Deals;
