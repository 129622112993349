import moment from 'moment/moment';
import React from 'react';

const ReportDropdownItem = ({ item, prettyDate }) => {
  let reportDate;
  try {
    reportDate = item?.value2?.split('T')[0] || item?.reportDate;
  } catch (e) {
    reportDate = item?.reportDate || item?.date;
  }
  return (
    <div className="d-flex flex-column align-items-start">
      {prettyDate ? (
        <h6 className="mb-0">{prettyDate}</h6>
      ) : (
        <h6 className="mb-0">{moment(reportDate).format('MMMM YYYY')}</h6>
      )}
    </div>
  );
};

export default ReportDropdownItem;
