import IdfTooltip from '../idfComponents/idfTooltip';
import Avatar from '../Avatar';
import { DATE_FORMAT_MONTH, setDateFormat } from '../../utils/Utils';
import React, { useEffect, useState } from 'react';
import { useProfileContext } from '../../contexts/profileContext';
import Skeleton from 'react-loading-skeleton';
import auditService from '../../services/audit.service';
import userService from '../../services/user.service';

const LastModifiedOn = ({ data }) => {
  const [user, setUser] = useState(data?.assigned_user || {});
  const { profileInfo } = useProfileContext();
  const [loader, setLoader] = useState(false);
  const getAuditAndUser = async () => {
    try {
      setLoader(true);
      const params = {
        resources: [
          {
            resourceType: `ne 'external'`,
          },
        ],
      };
      params.resourceId = data.id;
      params.action = 'update';
      const { data: audit } = await auditService.getAudits(params, {
        page: 1,
        limit: 1,
      });
      if (audit?.length) {
        const actorId = audit[0].actorId;
        // if audit says last edited guy is logged in one then just set from context
        if (actorId === profileInfo?.id) {
          setLoader(false);
          setUser(profileInfo);
        } else {
          // else get by user
          const userData = await userService.getUserById(actorId);
          setLoader(false);
          const name =
            userData?.name ||
            `${userData?.first_name} ${userData?.last_name || ''}`;
          setUser({ ...userData, name });
        }
      }
    } catch (e) {
      console.log('e', e);
    }
  };
  useEffect(() => {
    getAuditAndUser();
  }, [profileInfo, data]);
  return (
    <div className="pipeline-overview-row">
      {loader ? (
        <Skeleton width={100} height={5} />
      ) : (
        <p className="d-flex align-items-center gap-1 pt-4 w-100">
          <IdfTooltip text={`${user?.first_name} ${user?.last_name}`}>
            <Avatar user={user} defaultSize="xs" />
          </IdfTooltip>
          Last Modified on{' '}
          <span className="font-weight-semi-bold">
            {setDateFormat(data?.date_modified, DATE_FORMAT_MONTH)}
          </span>{' '}
        </p>
      )}
    </div>
  );
};

export default LastModifiedOn;
