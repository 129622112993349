import React from 'react';
import MaterialIcon from '../../../commons/MaterialIcon';

const PaymentStatsWidget = ({ widgetConfig, whenPrinting }) => {
  const getTrendIcon = (trend) => {
    switch (trend) {
      case 'up':
        return (
          <MaterialIcon
            icon={`arrow_drop_down`}
            className="text-green d-flex"
          />
        );
      case 'down':
        return (
          <MaterialIcon icon={`arrow_drop_down`} className="text-red d-flex" />
        );
      default:
        return (
          <MaterialIcon
            icon={`arrow_drop_down`}
            clazz="text-green hidden opacity-0"
          />
        );
    }
  };

  const formatNumber = (num) => {
    return num.toLocaleString('en-US');
  };

  const getTrendDetails = (type) => {
    const { todayPayments = 0, futurePayments = 0 } =
      widgetConfig?.data[type] || {};

    if (!todayPayments || !futurePayments) {
      return {
        trending: '',
        icon: (
          <MaterialIcon
            icon={`arrow_drop_down`}
            clazz="text-green hidden opacity-0"
          />
        ),
        color: 'text-black',
      };
    }

    const trending = futurePayments > todayPayments;
    return {
      trending,
      icon: getTrendIcon(trending ? 'up' : 'down'),
      color: trending ? 'text-green' : 'text-red',
    };
  };

  return (
    <div
      className="widget-table widget-border-2 pt-2"
      style={{
        borderRadius: 'var(--rpt-widget-border-radius)',
        background: '#ffffff',
      }}
    >
      {whenPrinting ? (
        <div className="widget-row align-items-center widget-heading py-1">
          <h2 className="widget-cell align-items-start"></h2>
          <h3 className="widget-cell fs-8 align-items-start">Today</h3>
          <h3 className="widget-cell fs-8 align-items-start">Future</h3>
        </div>
      ) : (
        <div className="widget-row align-items-center widget-heading py-xl-2">
          <h2 className="widget-cell align-items-start"></h2>
          <h3 className="widget-cell align-items-start">Today</h3>
          <h3 className="widget-cell align-items-start">Future</h3>
        </div>
      )}
      {widgetConfig?.items?.map((item, index) => {
        const { type } = item;
        const trendDetails = getTrendDetails(type);
        return (
          <div
            key={index}
            className={`widget-row align-items-center ${
              whenPrinting ? 'py-1' : 'py-xl-4'
            }`}
          >
            <div className="widget-cell flex-column gap-1">
              <div
                className="d-flex align-items-center bg-primary-soft rounded-circle justify-content-center"
                style={{
                  width: whenPrinting ? 30 : 45,
                  height: whenPrinting ? 30 : 45,
                }}
              >
                <MaterialIcon
                  icon={item.icon}
                  clazz={`text-primary`}
                  size={whenPrinting ? 'fs-5' : 'fs-4'}
                  filled
                />{' '}
              </div>
              <span
                className={`font-weight-semi-bold pl-1 ${
                  whenPrinting ? 'fs-9 sub-text' : 'fs-7'
                }`}
              >
                {item.type}
              </span>
            </div>
            <div className="widget-cell">
              <div
                className="d-flex flex-column align-items-start justify-content-start"
                style={{ width: 80 }}
              >
                <span
                  className={`font-weight-light ${
                    whenPrinting ? 'fs-9 sub-text' : 'fs-8'
                  }`}
                >
                  Payments
                </span>
                <span
                  className={`font-weight-semi-bold ${
                    whenPrinting ? 'fs-9 sub-text' : 'fs-8'
                  }`}
                >
                  {formatNumber(
                    widgetConfig?.data[item.type]?.todayPayments || 0
                  )}
                </span>
              </div>
              <div className="d-flex flex-column pl-4_2 align-items-start justify-content-start">
                <span
                  className={`font-weight-light ${
                    whenPrinting ? 'fs-9 sub-text' : 'fs-8'
                  }`}
                >
                  Amount
                </span>
                <span
                  className={`font-weight-semi-bold ${
                    whenPrinting ? 'fs-9 sub-text' : 'fs-8'
                  }`}
                >
                  $
                  {formatNumber(
                    widgetConfig?.data[item.type]?.todayAmount || 0
                  )}
                </span>
              </div>
            </div>
            <div
              className="widget-cell position-relative"
              style={{ top: whenPrinting ? 4 : 2 }}
            >
              <div
                className="d-flex flex-column align-items-start"
                style={{ width: 80 }}
              >
                <span
                  className={`font-weight-light ${
                    whenPrinting ? 'fs-9 sub-text' : 'fs-8'
                  }`}
                >
                  Payments
                </span>
                <div className="d-flex">
                  <span
                    className={`font-weight-semi-bold ${
                      whenPrinting ? 'fs-9 sub-text' : 'fs-7'
                    } ${trendDetails.color}`}
                  >
                    {formatNumber(
                      widgetConfig?.data[item.type]?.futurePayments || 0
                    )}
                  </span>
                  <div
                    className={`${trendDetails.color} ${
                      trendDetails.trending ? 'rotate-180' : ''
                    }`}
                  >
                    {trendDetails.icon}
                  </div>
                </div>
              </div>
              <div className="d-flex flex-column align-items-start pl-4_2">
                <span
                  className={`font-weight-light ${
                    whenPrinting ? 'fs-9 sub-text' : 'fs-8'
                  }`}
                >
                  Amount
                </span>
                <span
                  className={`font-weight-semi-bold ${
                    whenPrinting ? 'fs-9 sub-text' : 'fs-7'
                  }`}
                >
                  $
                  {formatNumber(
                    widgetConfig?.data[item.type]?.futureAmount || 0
                  )}
                </span>
              </div>
            </div>
            {index < widgetConfig?.items?.length - 1 && <hr className="" />}
          </div>
        );
      })}
    </div>
  );
};

export default PaymentStatsWidget;
