import { useParams, Redirect } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { findIndex } from 'lodash';
import Heading from '../../../components/heading';
import AddContent from '../../../components/peopleProfile/AddContent';
import dealService from '../../../services/deal.service';
import PipelineOverview from './PipelineOverview';
import {
  CANT_UPDATE_OVERVIEW_INFO,
  DEAL_UPDATED,
  NO_DEAL,
  SOMETHING_IS_WRONG,
} from '../../../utils/constants';
import stringConstants from '../../../utils/stringConstants.json';

import PipelineHeader from './PipelineHeader';
import AlertWrapper from '../../../components/Alert/AlertWrapper';
import Alert from '../../../components/Alert/Alert';
import {
  DetailTabs,
  getProductsTotalAmount,
  isModuleAllowed,
  isPermissionAllowed,
} from '../../../utils/Utils';
import userService from '../../../services/user.service';
import fieldService from '../../../services/field.service';
import organizationService from '../../../services/organization.service';
import { useModuleContext } from '../../../contexts/moduleContext';
import AddDeal from '../../../components/peopleProfile/deals/AddDeal';
import { LoadingDetailSkeleton } from '../../../components/LoadingDetailComponent';
import feedService from '../../../services/feed.service';
import activityService from '../../../services/activity.service';
import { useTenantContext } from '../../../contexts/TenantContext';
import PipelineCompanyModal from './PipelineCompanyModal';
import PipelineNamePriceEditModal from './PipelineNamePriceEditModal';
import contactService from '../../../services/contact.service';

const constants = stringConstants.deals.contacts.profile;
const TABS = DetailTabs;

const Pipeline = () => {
  const params = useParams();
  const { id, activityId } = params || {};
  const [deal, setDeal] = useState({});
  const [loading, setLoading] = useState(false);
  const [, setIsLoading] = useState(false);
  const [profileInfo, setProfileInfo] = useState({});
  const [refreshTabCounts, setRefreshTabCounts] = useState(0);
  const [refreshRecentFiles, setRefreshRecentFiles] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [, setProductsTotalAmount] = useState(0);
  const [refreshOwners, setRefresOwners] = useState(false);
  const [openCloneModal, setOpenCloneModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [, setOpenList] = useState(false);
  const [, setActivityIdOpen] = useState(activityId);
  const [me, setMe] = useState(null);
  const [refreshPipelineStage, setRefreshPipelineStage] = useState(1);
  const [refreshDeal, setRefreshDeal] = useState(false);
  const { tenant } = useTenantContext();
  const [tabCounts, setTabCounts] = useState({
    [TABS.Timeline]: 0,
    [TABS.Notes]: 0,
    [TABS.Activities]: 0,
    [TABS.Files]: 0,
    [TABS.Products]: 0,
    [TABS.Contacts]: 0,
  });
  const [companyInfoModal, setCompanyInfoModal] = useState(false);
  const [editInfoModal, setEditInfoModal] = useState(false);
  const [editInfoLoading, setEditInfoLoading] = useState(true);
  const { moduleMap } = useModuleContext();
  const isPrincipalOwner =
    me && deal
      ? me?.role?.admin_access ||
        me?.role?.owner_access ||
        deal?.assigned_user_id === me?.id
      : false;

  useEffect(() => {
    if (refreshOwners) {
      setRefresOwners(false);
    }
  }, [refreshOwners]);

  const loadCounts = async () => {
    const updatedType = 'pipelines';
    const queryParams = {
      page: 1,
      limit: 1,
      self: true,
    };

    const dealTabs = [
      {
        tabId: TABS.Timeline,
        module: `${updatedType}_timeline`,
        count: 0,
        countApiCall: () => Promise.resolve({ pagination: { count: 0 } }),
      },
      {
        tabId: TABS.Notes,
        module: `${updatedType}_notes`,
        count: 0,
        countApiCall: () =>
          feedService.getNote(
            { contactId: null, organizationId: null, dealId: id },
            queryParams
          ),
      },
      {
        tabId: TABS.Activities,
        module: `activities_pipeline_activities`,
        count: 0,
        countApiCall: () =>
          activityService.getActivity(
            {
              contactId: null,
              organizationId: null,
              dealId: id,
            },
            queryParams
          ),
      },
      {
        tabId: TABS.Files,
        module: `${updatedType}_files`,
        count: 0,
        countApiCall: () =>
          feedService.getFiles(
            { contactId: null, organizationId: null, dealId: id },
            queryParams
          ),
      },
      {
        tabId: TABS.Products,
        module: 'pipelines_products',
        count: 0,
        countApiCall: () => dealService.getDealProducts(id, queryParams),
      },
      {
        tabId: TABS.Contacts,
        module: 'pipelines_products',
        count: 0,
        countApiCall: () =>
          contactService.getContactsByOrganizationId(
            { organizationId: deal?.organization?.id },
            queryParams
          ),
      },
    ];
    const enabledTabs = dealTabs?.filter((item) => {
      return isModuleAllowed(tenant?.modules, item?.module);
    });

    const countCalls = enabledTabs.map((cl) => cl.countApiCall());
    const responses = await Promise.allSettled(countCalls);
    const Counts = {};
    enabledTabs.forEach((tb, index) => {
      if (responses[index].status === 'fulfilled') {
        Counts[tb.tabId] = responses[index]?.value?.pagination?.count || 0;
      } else {
        Counts[tb.tabId] = 0;
        console.error(
          `Error in API call for tab ${tb.tabId}:`,
          responses[index].reason
        );
      }
    });
    setTabCounts(Counts);
  };

  useEffect(() => {
    if (params?.id) {
      getDeal();
      getCurrentUser();
    }
  }, [params?.id]);

  useEffect(() => {
    if (deal?.id) {
      loadCounts();
    }
  }, [deal, refreshTabCounts]);
  useEffect(() => {
    if (refreshRecentFiles) {
      getDeal();
      setRefreshRecentFiles(false);
    }
  }, [refreshRecentFiles]);

  const getCurrentUser = async () => {
    const me = await userService
      .getUserInfo()
      .catch((err) => console.error(err));

    setMe(me);
  };

  const getDeal = async (message, noFullLoading) => {
    !noFullLoading && setLoading(true);
    try {
      const { data } = await fieldService.getFields('deal', {
        usedField: true,
      });
      const {
        data: { data: customFields },
      } = await dealService.getCustomField(id, {
        page: 1,
        limit: 50,
      });
      let customValues = {};
      if (message) {
        setSuccessMessage(message);
        setActivityIdOpen('');
      }
      setIsLoading(true);

      const deal = await dealService
        .getDealById(id)
        .catch((err) => console.log(err));
      data.forEach((field) => {
        if (field.isCustom) {
          customFields.forEach((item) => {
            if (field.key === item?.field?.key && field.field_type !== 'DATE') {
              customValues = {
                ...customValues,
                [field.key?.toLowerCase().replace(/\s+/g, '')]:
                  field.field_type === 'CURRENCY'
                    ? item.value.substring(1)
                    : item.value,
              };
            } else if (
              field.key === item?.field?.key &&
              field.field_type === 'DATE'
            ) {
              const dateValue = new Date(item.value);
              if (!isNaN(dateValue)) {
                customValues = {
                  ...customValues,
                  [field.key?.toLowerCase().replace(/\s+/g, '')]: dateValue,
                };
              } else {
                // Handle invalid date format here (e.g., set a default value)
                customValues = {
                  ...customValues,
                  [field.key?.toLowerCase().replace(/\s+/g, '')]: null, // or provide a default date value
                };
              }
            }
          });
        }
      });

      setDeal({ ...deal, ...customValues });

      setIsLoading(false);

      setRefreshPipelineStage((prevState) => prevState + 1);
      setRefreshDeal(false);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  if (!deal) {
    return <div>{NO_DEAL}</div>;
  }

  const classnames = (index, stages, currentDeal) => {
    const stageIndex = findIndex(stages, {
      id: currentDeal.tenant_deal_stage_id || 'cold',
    });

    if (index === stageIndex) {
      return 'bg-primary';
    }
    return 'bg-soft-primary';
  };
  useEffect(() => {
    if (params?.id) {
      getDeal();
    }
  }, [params?.id, refreshDeal]);
  useEffect(() => {
    deal?.deal_products?.length &&
      setProductsTotalAmount(getProductsTotalAmount(deal.deal_products));
  }, [deal?.deal_products]);

  if (deal?.deleted) {
    return <Redirect to="/" />;
  }
  const goToHome = () => {
    history.push('/');
  };
  const getProfileInfo = async (message) => {
    if (message) {
      setActivityIdOpen(''); // After activity update to not open activity modal
      switch (message) {
        case constants.contactUpdated:
          setSuccessMessage(constants.contactUpdated);

          break;
        case constants.errorContactUpdated:
          setErrorMessage(constants.errorContactUpdated);
          break;
        default:
          setSuccessMessage(message);
      }
    }
    setIsLoading(true);
    const { data } = await fieldService.getFields('organization', {
      usedField: true,
    });
    const {
      data: { data: customFields },
    } = await organizationService.getCustomField(deal?.organization?.id, {
      page: 1,
      limit: 50,
    });
    let customValues = {};
    Promise.all([
      organizationService.getOrganizationById(deal?.organization?.id),
      organizationService.getFieldByOrganization(deal?.organization?.id, {}),
    ])
      .then(([result, response]) => {
        if (!result) {
          goToHome();
        }
        const fields = response?.data?.sort((a, b) => {
          return a.field.order - b.field.order;
        });
        data.forEach((field) => {
          if (field.isCustom) {
            customFields.forEach((item) => {
              if (field.key === item.field.key && field.field_type !== 'DATE') {
                customValues = {
                  ...customValues,
                  [field.key?.toLowerCase().replace(/\s+/g, '')]:
                    field.field_type === 'CURRENCY'
                      ? item.value.substring(1)
                      : item.value,
                };
              } else if (
                field.key === item.field.key &&
                field.field_type === 'DATE'
              ) {
                customValues = {
                  ...customValues,
                  [field.key?.toLowerCase().replace(/\s+/g, '')]: new Date(
                    item.value
                  ),
                };
              }
            });
          }
        });
        setProfileInfo({ ...result, ...customValues, fields });
        setIsLoading(false);
      })
      .catch(() => {
        goToHome();
      });
  };

  useEffect(() => {
    if (deal?.organization?.id && params?.id) {
      getProfileInfo();
    }
  }, [deal?.organization?.id]);

  const openCompanyModal = () => {
    if (moduleMap.organization) {
      setCompanyInfoModal(true);
    }
  };

  const updateDeal = async (dealFormData) => {
    setEditInfoLoading(true);
    const { name, amount } = dealFormData || {};

    const parsedAmount = parseInt(amount);

    const updateFields = {
      name,
      amount: Number.isFinite(parsedAmount) ? parsedAmount : undefined,
    };

    const resp = await dealService
      .updateDeal(deal.id, updateFields)
      .catch(() => setErrorMessage(SOMETHING_IS_WRONG));

    if (resp?.response?.data?.errors[0]?.message) {
      setEditInfoLoading(false);
      return setErrorMessage(CANT_UPDATE_OVERVIEW_INFO);
    }
    const { data } = resp || {};

    if (data.length) {
      setSuccessMessage(DEAL_UPDATED);
      getDeal('', true);
    }
    setRefresOwners(true);
    setEditInfoModal(false);
    setEditInfoLoading(false);
  };

  return (
    <>
      <AlertWrapper>
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
        <Alert
          color="success"
          message={successMessage}
          setMessage={setSuccessMessage}
        />
      </AlertWrapper>
      {loading ? (
        <div className="p-3 bg-white">
          <LoadingDetailSkeleton />
        </div>
      ) : (
        <>
          <Heading useBc title={deal.name} showGreeting={false} />
          <div className="bg-white">
            {moduleMap.deal && Object.keys(deal)?.length > 0 && (
              <PipelineHeader
                moduleMap={moduleMap}
                classnames={classnames}
                deal={deal}
                setOpenCloneModal={setOpenCloneModal}
                setOpenEditModal={setOpenEditModal}
                getDeal={getDeal}
                refreshOwners={refreshOwners}
                setRefresOwners={setRefresOwners}
                isPrincipalOwner={isPrincipalOwner}
                refreshPipelineStage={refreshPipelineStage}
                withFollowers={params?.id || deal.id}
                mainOwner={deal?.assigned_user}
                service={dealService}
                openCompanyModal={openCompanyModal}
                setEditInfoModal={setEditInfoModal}
              />
            )}
            <div className="row">
              <div className="col-lg-4 py-3_1 pr-0 pipline-tab-aside custom-scrollbar">
                {moduleMap.deal &&
                  Object.keys(deal)?.length > 0 &&
                  isPermissionAllowed('organizations', 'view') && (
                    <PipelineOverview
                      moduleMap={moduleMap.deal.singular}
                      moduleData={moduleMap}
                      deal={deal}
                      setRefresOwners={setRefresOwners}
                      getDeal={getDeal}
                      isPrincipalOwner={isPrincipalOwner}
                    />
                  )}
              </div>
              <div className="col-lg-8 border-left pl-0">
                <div className="pipeline-tabs">
                  {moduleMap.task && Object.keys(deal)?.length > 0 && (
                    <AddContent
                      moduleMap={moduleMap}
                      dataType="deal"
                      type="pipelines"
                      activityType="pipeline"
                      dealId={params?.id ? params?.id : deal.id}
                      deal={deal}
                      organization={deal?.organization}
                      getDeal={getDeal}
                      setDeal={setDeal}
                      me={me}
                      setRefreshTabCounts={setRefreshTabCounts}
                      setRefreshDeal={setRefreshDeal}
                      contactInfo={deal?.contact}
                      organizationId={deal?.organization?.id}
                      contactIs={'organization'}
                      getProfileInfo={getDeal}
                      refreshPipelineStage={refreshPipelineStage}
                      isDeal={true}
                      refreshRecentFiles={refreshRecentFiles}
                      setRefreshRecentFiles={setRefreshRecentFiles}
                      isPrincipalOwner={isPrincipalOwner}
                      profileInfo={profileInfo}
                      tabCounts={tabCounts}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          {openCloneModal && (
            <AddDeal
              className="btn-transparent"
              setOpenDeal={setOpenCloneModal}
              openDeal={openCloneModal}
              setOpenList={setOpenList}
              errorMessage={errorMessage}
              pipeline={deal?.tenant_deal_stage?.pipeline}
              selectedStage={deal?.tenant_deal_stage}
              moduleMap={moduleMap}
              dealData={deal}
              isEdited={false}
              setErrorMessage={setErrorMessage}
              successMessage={successMessage}
              setSuccessMessage={setSuccessMessage}
            />
          )}

          {openEditModal && (
            <AddDeal
              className="btn-transparent"
              setOpenDeal={setOpenEditModal}
              openDeal={openEditModal}
              setOpenList={setOpenList}
              errorMessage={errorMessage}
              pipeline={deal?.tenant_deal_stage?.pipeline}
              selectedStage={deal?.tenant_deal_stage}
              moduleMap={moduleMap}
              dealData={deal}
              getDeal={getDeal}
              isEdited={true}
              setErrorMessage={setErrorMessage}
              successMessage={successMessage}
              setSuccessMessage={setSuccessMessage}
            />
          )}

          <PipelineCompanyModal
            showModal={companyInfoModal}
            setShowModal={setCompanyInfoModal}
            deal={deal}
          />

          <PipelineNamePriceEditModal
            show={editInfoModal}
            setShow={setEditInfoModal}
            deal={deal}
            onHandleSubmit={updateDeal}
            isLoading={editInfoLoading}
          />
        </>
      )}
    </>
  );
};

export default Pipeline;
