import React, { useEffect, useState } from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import {
  NO_REPORT_SELECTED,
  NO_REPORTS_AVAILABLE,
} from '../../utils/constants';
import Alert from '../../components/Alert/Alert';
import { SwitchAllReports, validInsights } from './SwitchAllReports';
import { cubeService } from '../../services';
import AlertWrapper from '../../components/Alert/AlertWrapper';
import NoDataFound from '../../components/commons/NoDataFound';
import DashboardService from '../../services/dashboard.service';
import {
  DATE_FORMAT,
  scrollToTop,
  isMatchInCommaSeperated,
} from '../../utils/Utils';
import DashboardComponent from '../Overview/dashboard/DashboardComponent';
import MaterialIcon from '../../components/commons/MaterialIcon';
import DatePicker from '../../components/dealsBreakdown/DatePicker';
import moment from 'moment';
import { useTenantContext } from '../../contexts/TenantContext';
import { useHistory } from 'react-router';
import TrainingUserTranscriptComponent from '../Overview/dashboard/TrainingUserTranscriptComponent';
import { ReportSkeletonLoader } from './ReportSkeletonLoader';
import TrainingLeaderBoardTranscriptComponent from '../Overview/dashboard/TrainingLeaderBoardComponent';
import TrainingLearnDashboardTranscriptComponent from '../Overview/dashboard/TrainingLearnDashboardComponent';
import { LEARN_OVERVIEW_CONSTANT_KEY } from '../../components/reports/reports.constants';
import TrainingLeassonStatistics from '../Overview/dashboard/TrainingLeassonStatistics';
import ChecklistReportsComponent from '../Overview/dashboard/ChecklistReportsComponent';
import ChecklistOverviewReportsComponent from '../Overview/dashboard/ChecklistOverviewReportsComponent';
import TextOverflowTooltip from '../../components/commons/TextOverflowTooltip';
import TrainingLeassonDetails from '../Overview/dashboard/TrainingLeassonDetails';

const format = DATE_FORMAT;
const TrainingDashboardComponents = {
  Training_AssignmentDetails: 'Training_AssignmentDetails',
  Training_AssignmentOrganizationSnapshot:
    'Training_AssignmentOrganizationSnapshot',
  Training_AssignmentTeamSnapshot: 'Training_AssignmentTeamSnapshot',
  Training_AssignmentStatistics: 'Training_AssignmentStatistics',
  Training_UserTranscript: 'Training_UserTranscript',
  Training_Leaderboard: 'Training_Leaderboard',
  Training_LearnDashboard: 'Training_LearnDashboard',
  TrainingLessonsStatistics: 'Lesson Statistics',
  Training_LessonDetails: 'Lessons Details',
};

const TrainingDashboardComponentsSort = {
  Training_AssignmentOrganizationSnapshot: 1,
  Training_AssignmentTeamSnapshot: 2,
  Training_AssignmentStatistics: 3,
  Training_AssignmentDetails: 4,
  'Lesson Statistics': 5,
  Training_UserTranscript: 6,
  Training_LessonDetails: 7,
};
const TrainingComponentsName = {
  Training_AssignmentDetails: 'Assignment Details',
  Training_AssignmentOrganizationSnapshot: 'Assignments Organization Snapshot',
  Training_AssignmentTeamSnapshot: 'Assignments Team Snapshot',
  Training_AssignmentStatistics: 'Assignment Statistics',
  Training_UserTranscript: 'User Transcript',
  Training_Leaderboard: 'Leaderboard',
  TrainingLessonsStatistics: 'Lesson Statistics',
  Training_LessonDetails: 'Lessons Details',
};

const ChecklistComponentsName = {
  Checklists_ChecklistsOverview: 'Checklists Overview',
  Checklists_ChecklistsReport: 'Checklists Report',
};
const ChecklistsDashboardComponents = {
  Checklists_ChecklistsOverview: 'Checklists_ChecklistsOverview',
  Checklists_ChecklistsReport: 'Checklists_ChecklistsReport',
};
const ChecklistsOrder = [
  'Checklists_ChecklistsOverview',
  'Checklists_ChecklistsReport',
];
const TrainingComponentsIcons = {
  Training_AssignmentDetails: 'description',
  Training_AssignmentOrganizationSnapshot: 'bar_chart',
  Training_AssignmentTeamSnapshot: 'people',
  Training_AssignmentStatistics: 'pie_chart',
  Training_UserTranscript: 'text_snippet',
  Training_Leaderboard: 'trophy',
  Training_LearnDashboard: 'dashboard',
  Training_LessonDetails: 'text_snippet',
};

const DashboardIcons = {
  Overview: 'overview',
  Training: 'school',
  Activities: 'event_available',
  Deal: 'monetization_on',
  Portfolio: 'business_center',
  Checklists: 'checklist',
};
const InsightReports = () => {
  const [dashboardList, setDashboardList] = useState([]);
  const [selectedDashboard, setSelectedDashboard] = useState({});
  const { tenant } = useTenantContext();
  const [insightReport, setInsightReport] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [active, setActive] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [dashboardComponents, setDashboardComponents] = useState([]);
  const [dashboardWithComponents, setDashboardWithComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState({});
  const [checklistId, setChecklistId] = useState('');

  const [isUserDashboard, setIsUserDashboard] = useState(false);
  const [query, setQuery] = useState({});
  const [staticComponent, setStaticComponent] = useState({});
  const [dateRange, setDateRange] = useState({});
  const history = useHistory();
  useEffect(() => {
    if (selectedComponent?.component) {
      let updatedTimeDimensions = [
        ...selectedComponent?.component?.analytic?.timeDimensions,
      ];
      if (updatedTimeDimensions && updatedTimeDimensions.length) {
        delete updatedTimeDimensions[0]?.compareDateRange;

        if (dateRange.startDate && dateRange.endDate) {
          updatedTimeDimensions[0].dateRange = [
            moment(dateRange.startDate).format(format),
            moment(dateRange.endDate).format(format),
          ];
        } else {
          updatedTimeDimensions = undefined;
        }
      }
      const queryBackup = {
        ...selectedComponent,
        component: {
          ...selectedComponent.component,
          analytic: {
            ...selectedComponent.component.analytic,
            timeDimensions: updatedTimeDimensions,
          },
        },
      };

      setSelectedComponent({});
      setQuery({});
      scrollToTop();

      setDashboardComponents(
        [...dashboardComponents].map((comp) => ({
          ...comp,
          component:
            comp.componentId === queryBackup.componentId
              ? queryBackup.component
              : comp.component,
        }))
      );

      setTimeout(() => {
        setQuery(queryBackup.component.analytic);
        setSelectedComponent(queryBackup);
      });
    }
  }, [dateRange]);

  const dashboardComponentConfig = {
    hideActions: true,
    headingWithoutDash: true,
    wrap: 'text-nowrap', // for wrapping/truncate
  };

  const getDefaultReports = (analytics) => {
    const typeMap = {
      Course: 'TRAINING REPORTS',
      CourseProgress: 'TRAINING REPORTS',
      Deal: 'DEALS REPORTS',
      Lesson: 'TRAINING REPORTS',
      LessonProgress: 'TRAINING REPORTS',
      Training: 'TRAINING REPORTS',
      SelfAssessment: 'TRAINING REPORTS',
    };
    const reports = analytics.reduce(
      (acc, analytic, idx) => {
        if (!validInsights.includes(analytic.name)) {
          return acc;
        }
        const type = typeMap[analytic.type];
        acc[type]?.push({
          ...analytic,
          id: idx,
          insightName: analytic.name,
        });
        acc[type]?.sort((a, b) => a.position - b.position);

        return acc;
      },
      {
        'DEALS REPORTS': [],
        'TRAINING REPORTS': [],
      }
    );

    return reports;
  };

  const getDashboardComponents = (dashboard) => {
    return (
      dashboardWithComponents.find((d) => d?.dashboard?.id === dashboard?.id)
        ?.components || []
    );
  };

  const getDashboardFromList = (list, dashId) => {
    return list.find(({ dashboard }) => dashboard.id === dashId) || {};
  };

  const getComponentFromList = (list, cId) => {
    return list.find((cmp) => cmp.componentId === cId) || {};
  };

  const buildLegacyReports = (dashboard, list) => {
    return [...list].map((l) => {
      return {
        component: {
          ...l,
          componentId: l.id,
          analytic: l,
        },
        insightName: l.name,
        rptType: 'legacy',
        componentId: l.id,
        dashboardId: dashboard.id,
      };
    });
  };

  const getDashboards = async () => {
    setLoading(true);

    const urlParams = new URLSearchParams(history.location.search);
    const dashId = urlParams.get('dashboard');
    const componentId = urlParams.get('component');

    const requests = [];
    requests.push(cubeService.getAnalytics({ isPublic: true }));
    requests.push(DashboardService.getDashboards());
    const responses = await Promise.all(requests);
    const defaultReports = getDefaultReports(responses[0]); // this comes as object

    const { data } = responses[1];

    const learnDashboard = data?.find((item) => {
      return item?.name === 'Training_LearnDashboard';
    });
    const TrainingComponents = data?.filter((item) => {
      return (
        item?.name !== 'Training_Leaderboard' &&
        item?.name !== 'Training_LearnDashboard' &&
        TrainingDashboardComponents[item?.name] &&
        item?.name !== 'Training_UserTranscript'
      );
    });
    const ChecklistsComponents = data?.filter((item) => {
      return ChecklistsDashboardComponents[item?.name];
    });
    const UserComponent = data?.find((item) => {
      return item?.name === 'Training_UserTranscript';
    });

    const leaderBoard = data?.find((item) => {
      return item?.name === 'Training_Leaderboard';
    });
    const filteredData = data.filter(
      (dashboard) =>
        dashboard?.name !== TrainingDashboardComponents[dashboard?.name] &&
        dashboard?.name !== ChecklistsDashboardComponents[dashboard?.name]
    );
    const dashboards = filteredData?.map((d) => ({
      ...d,
      key: d.id,
    }));
    const desiredOrder = [
      'Overview',
      'Portfolio',
      'Deal',
      'Activities',
      'Checklists',
      'Training',
    ];
    const sortedData = dashboards.sort((a, b) => {
      return desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name);
    });
    const uniqueDashboards = sortedData.filter((dashboard) => {
      const activityName =
        dashboard?.name === 'Activities' ? 'activity' : dashboard?.name;
      const settingsInput = 'reporting_' + activityName;
      return (
        tenant.modules === '*' ||
        isMatchInCommaSeperated(tenant.modules, settingsInput)
      );
    });

    const componentsRequest = uniqueDashboards.map((dashboard) => {
      return DashboardService.getDashboardsComponents(dashboard.id);
    });
    const componentsResponse = await Promise.all(componentsRequest);

    let dashboardWithComponents = componentsResponse
      .map((dwc) => {
        return {
          dashboard: uniqueDashboards.find(
            (ud) => ud.id === dwc.data[0]?.dashboardId
          ),
          components: dwc.data,
        };
      })
      .filter((f) => f.components.length);
    const TrainingDashboard = uniqueDashboards.find(
      (ud) => ud.name === 'Training'
    );
    const ChecklistDashboard =
      uniqueDashboards.find((ud) => ud.name === 'Checklists') || {};
    if (Object.keys(ChecklistDashboard)?.length > 0) {
      dashboardWithComponents.push({
        dashboard: uniqueDashboards.find((ud) => ud.name === 'Checklists'),
        components: [],
      });
    }

    dashboardWithComponents.push({
      dashboard: uniqueDashboards.find((ud) => ud.name === 'Training'),
      components: [],
    });
    const UpdatedTrainingComponents = TrainingComponents?.map((component) => ({
      ...component,
      insightName: component?.name,
      name: TrainingComponentsName[component?.name],
      id: component?.id,
      componentId: 1298,
      rptType: 'legacy',
      sort: TrainingDashboardComponentsSort[component?.name],
      component: {
        id: component?.id,
        insightName: component?.name,
        name: TrainingComponentsName[component?.name],
        analytic: {
          icon: TrainingComponentsIcons[component?.name] || 'people',
          timeDimensions: [],
        },
      },
      dashboardId: TrainingDashboard?.id,
    }));
    const sortedDashboardComponents = ChecklistsComponents?.sort((a, b) => {
      const nameA = a?.name || '';
      const nameB = b?.name || '';
      const indexA = ChecklistsOrder.indexOf(nameA);
      const indexB = ChecklistsOrder.indexOf(nameB);

      return indexA - indexB;
    });

    const UpdatedChecklistComponents =
      sortedDashboardComponents?.length > 0
        ? sortedDashboardComponents?.map((component) => ({
            ...component,
            insightName: component?.name,
            name: ChecklistComponentsName[component?.name],
            id: component?.id,
            componentId: 1298,
            rptType: 'legacy',
            sort: TrainingDashboardComponentsSort[component?.name],
            component: {
              id: component?.id,
              insightName: component?.name,
              name: ChecklistComponentsName[component?.name],
              analytic: {
                icon: TrainingComponentsIcons[component?.name] || 'people',
              },
            },
            dashboardId: ChecklistDashboard?.id,
          }))
        : [];

    UpdatedTrainingComponents.sort((a, b) => a.sort - b.sort);
    // updating Training with default/legacy reports too
    dashboardWithComponents = dashboardWithComponents.map((dwc) => ({
      ...dwc,
      components:
        dwc.dashboard?.name === 'Training'
          ? [
              {
                ...learnDashboard,
                insightName: learnDashboard?.name,
                name: LEARN_OVERVIEW_CONSTANT_KEY,
                id: learnDashboard?.id,
                componentId: 1298,
                component: {
                  id: learnDashboard?.id,
                  analytic: {
                    icon: 'space_dashboard',
                  },
                },
                dashboardId: TrainingDashboard?.id,
              },
              {
                ...leaderBoard,
                insightName: leaderBoard?.name,
                name: 'Leaderboard',
                id: leaderBoard?.id,
                componentId: 1298,
                component: {
                  id: leaderBoard?.id,
                  analytic: {
                    icon: 'trophy',
                  },
                },
                dashboardId: TrainingDashboard?.id,
              },
              ...UpdatedTrainingComponents,
              ...dwc.components,
              ...buildLegacyReports(
                dwc.dashboard,
                defaultReports['TRAINING REPORTS']
              ),
              {
                insightName: 'Lesson Statistics',
                name: 'Lesson Statistics',
                id: 980,
                componentId: 1298,
                component: {
                  id: 980,
                  analytic: {
                    icon: 'text_snippet',
                  },
                },
                dashboardId: TrainingDashboard?.id,
              },
              {
                ...UserComponent,
                insightName: UserComponent?.name,
                name: 'User Transcript',
                id: UserComponent?.id,
                componentId: 1298,
                component: {
                  id: UserComponent?.id,
                  analytic: {
                    icon: 'text_snippet',
                  },
                },
                dashboardId: TrainingDashboard?.id,
              },
            ]
          : dwc.dashboard?.name === 'Deal'
          ? [
              ...dwc.components,
              ...buildLegacyReports(
                dwc.dashboard,
                defaultReports['DEALS REPORTS']
              ),
            ]
          : dwc.dashboard?.name === 'Checklists'
          ? [...UpdatedChecklistComponents]
          : dwc.components,
    }));

    setLoading(false);
    const first = dashId
      ? getDashboardFromList(dashboardWithComponents, dashId)
      : uniqueDashboards[0];

    setDashboardList(uniqueDashboards);
    setActive(uniqueDashboards[0]?.id);
    setSelectedDashboard(first?.dashboard || first);
    setDashboardWithComponents(dashboardWithComponents);
    setDashboardComponents(
      first?.components || dashboardWithComponents[0]?.components
    );

    if (componentId) {
      const component = getComponentFromList(first?.components, componentId);
      const updatedDashboardComponents = [...first?.components].map((comp) => ({
        ...comp,
        isActive: comp.componentId === component.componentId,
      }));
      setDashboardComponents(updatedDashboardComponents);
      setTimeout(() => {
        setQuery(component.component.analytic);
        setSelectedComponent(component);
      });
    }
  };

  useEffect(() => {
    if (tenant) {
      getDashboards();
    }
  }, [tenant]);

  useEffect(() => {
    if (selectedDashboard) {
      setDashboardComponents(getDashboardComponents(selectedDashboard));
    }
  }, [selectedDashboard]);

  const Title = () => {
    return <div className="text-muted">{NO_REPORT_SELECTED}</div>;
  };

  const TitleNoReports = () => {
    return (
      <div className="text-muted font-size-md">{NO_REPORTS_AVAILABLE}</div>
    );
  };
  const handleComponentClick = (dashboardId, component) => {
    setInsightReport({});
    setSelectedComponent({});
    setQuery({});
    setStaticComponent({});
    scrollToTop();
    if (component?.component?.type2 === 'static') {
      // setStaticComponent(getStaticComponent(component?.component));
      setSelectedComponent(component);
      setInsightReport(component?.component);
    } else {
      if (component?.rptType === 'legacy') {
        setTimeout(() => {
          setSelectedComponent(component);
          setInsightReport(component?.component);
        });
      } else {
        setTimeout(() => {
          setQuery(component.component.analytic);
          setSelectedComponent(component);
        });
      }
    }
  };
  const handleCallUserComponent = (item) => {
    setIsUserDashboard(true);
    setSelectedComponent(item);
    scrollToTop();
    setChecklistId('');
  };

  const handleCallChecklistReport = (id) => {
    const ChecklistsComponents = dashboardWithComponents?.filter((item) => {
      return item?.dashboard?.name === 'Checklists';
    });
    const ChecklistReport =
      ChecklistsComponents?.length > 0 &&
      ChecklistsComponents[0].components?.find((item) => {
        return item?.name === 'Checklists Report';
      });
    setIsUserDashboard(true);
    setSelectedComponent(ChecklistReport);
    setChecklistId(id);
    scrollToTop();
  };
  const renderCollapseOfDashboard = (dashboard) => {
    const components = getDashboardComponents(dashboard);
    const subComponents = components.filter(
      (i) => i.dashboardId === dashboard.id
    );
    if (subComponents) {
      return (
        <>
          {subComponents.map((component) => (
            <Row
              key={component.id}
              onClick={() => {
                if (
                  component?.insightName === 'Training_UserTranscript' ||
                  component?.insightName === 'Training_Leaderboard' ||
                  component?.insightName === 'Training_LearnDashboard' ||
                  component?.insightName === 'Lesson Statistics' ||
                  component?.insightName === 'Checklists_ChecklistsReport' ||
                  component?.insightName === 'Checklists_ChecklistsOverview' ||
                  component?.insightName === 'Training_LessonDetails'
                ) {
                  handleCallUserComponent(component);
                } else {
                  setIsUserDashboard(false);
                  handleComponentClick(component?.dashboard?.id, component);
                }
              }}
              className={`cursor-pointer report-sub-menu align-items-center px-4_1 py-2 nav-link item-filter ${
                selectedComponent?.component?.id === component?.component?.id
                  ? 'text-primary active bg-primary-soft fw-600'
                  : ''
              }`}
            >
              <Col>
                <p className="d-flex align-items-center py-0 px-4 my-0 gap-2">
                  <span className="font-weight-medium font-size-sm2 mb-0">
                    <TextOverflowTooltip
                      text={
                        component?.component?.name === 'Assignment Progress'
                          ? 'Assignment Statistics'
                          : component?.component?.name?.split('-')[0]?.trim() ||
                            component?.name?.split('-')[0]?.trim()
                      }
                    />
                  </span>
                </p>
              </Col>
            </Row>
          ))}
        </>
      );
    } else {
      return <></>;
    }
  };

  const shouldShowDatePicker = () => {
    const keys = Object.keys(selectedComponent).length;
    return keys && selectedComponent.rptType !== 'legacy';
  };

  const handleToggleAccordion = (key) => {
    setActive(active === key ? null : key);
  };

  return (
    <div className="overflow-x-hidden">
      <AlertWrapper>
        <Alert message={successMessage} setMessage={setSuccessMessage} />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
        />
      </AlertWrapper>
      <div className="mt-0 settings-layout overflow-x-hidden">
        <div className="setting-sidebar">
          <h3 className="mt-0 px-2 pt-3">Reports</h3>
          {loading && (
            <>
              <ReportSkeletonLoader rows={15} height={30} />
            </>
          )}
          <ul className="list-unstyled mb-0 transparent-scroll-track">
            {dashboardList.map((i, index) => {
              return (
                <li key={index}>
                  <Accordion
                    defaultActiveKey={dashboardList[0]?.id || String(index)}
                    activeKey={active}
                  >
                    <Accordion.Toggle
                      as={Card.Header}
                      onClick={() => handleToggleAccordion(i.id)}
                      eventKey={i.id}
                      className="nav-subtitle p-2 bg-transparent border-0 card-title text-hover-primary cursor-pointer fs-7 text-black font-weight-medium text-capitalize"
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center gap-1">
                          <MaterialIcon
                            icon={DashboardIcons[i.name]}
                            clazz="text-black font-weight-normal"
                          />
                          <h5 className="mb-0 fs-7">
                            {i.name === 'Training' ? 'Learn' : i.name}
                          </h5>
                        </div>
                        <MaterialIcon
                          icon={active === i.id ? 'expand_more' : 'expand_less'}
                        />
                      </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={i.id}>
                      <div className="py-0 overflow-x-hidden">
                        {
                          <>
                            {loading ? (
                              <ReportSkeletonLoader rows={15} />
                            ) : (
                              <>
                                {dashboardComponents?.length > 0 ? (
                                  <> {renderCollapseOfDashboard(i)} </>
                                ) : (
                                  <NoDataFound
                                    title={<TitleNoReports />}
                                    icon="analytics"
                                    containerStyle="w-100 text-muted h-100"
                                    iconStyle="font-size-2xl"
                                  />
                                )}
                              </>
                            )}
                          </>
                        }
                      </div>
                    </Accordion.Collapse>
                  </Accordion>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="pl-0 bg-white flex-fill position-relative settings-content right-panel-setting">
          {!isUserDashboard && (
            <div
              className="position-absolute z-index-5"
              style={{
                top: 15,
                right: 35,
                pointerEvents: shouldShowDatePicker() ? '' : 'none',
                opacity: shouldShowDatePicker() ? 1 : 0,
              }}
            >
              <DatePicker
                range={dateRange}
                setRange={setDateRange}
                selectedDate={!selectedComponent?.component}
                extraClass="p-0"
              />
            </div>
          )}
          {isUserDashboard &&
          selectedComponent?.insightName === 'Training_UserTranscript' ? (
            <TrainingUserTranscriptComponent
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName === 'Training_Leaderboard' ? (
            <TrainingLeaderBoardTranscriptComponent
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName === 'Training_LearnDashboard' ? (
            <TrainingLearnDashboardTranscriptComponent
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
            />
          ) : isUserDashboard &&
            selectedComponent?.component?.name === 'Checklists Report' ? (
            <ChecklistReportsComponent
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
              checklistId={checklistId}
            />
          ) : isUserDashboard &&
            selectedComponent?.component?.name === 'Checklists Overview' ? (
            <ChecklistOverviewReportsComponent
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
              handleCallUserComponent={handleCallChecklistReport}
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName === 'Lesson Statistics' ? (
            <TrainingLeassonStatistics
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
            />
          ) : isUserDashboard &&
            selectedComponent?.insightName === 'Training_LessonDetails' ? (
            <TrainingLeassonDetails
              selectedComponent={selectedComponent}
              dashboard={selectedComponent}
            />
          ) : staticComponent?.component ? (
            <>{staticComponent?.component}</>
          ) : (
            <>
              {insightReport?.insightName || selectedComponent?.component ? (
                <>
                  {insightReport?.insightName ? (
                    SwitchAllReports({
                      insight: { ...insightReport },
                      insightName: selectedComponent?.component?.name,
                    })
                  ) : (
                    <DashboardComponent
                      item={selectedComponent}
                      query={query}
                      componentHeight={'auto'}
                      config={dashboardComponentConfig}
                    />
                  )}
                </>
              ) : (
                <NoDataFound
                  title={<Title />}
                  icon="analytics"
                  containerStyle="w-100 height-300 text-muted"
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default InsightReports;
