import React, { useState, useEffect } from 'react';
import ActivitiesChecklist from '../../components/checklist/ActivitiesChecklist';
import { ChecklistFilters } from '../../utils/constants';
import AlertWrapper from '../../components/Alert/AlertWrapper';
import Alert from '../../components/Alert/Alert';
import useCreateChecklist from '../../components/checklist/useCreateChecklist';
import { isModuleAllowed, isPermissionAllowed } from '../../utils/Utils';
import ButtonIcon from '../../components/commons/ButtonIcon';
import LayoutHead from '../../components/commons/LayoutHead';
import { useTenantContext } from '../../contexts/TenantContext';
import ChecklistFilterTabsDropdown from '../../components/commons/ChecklistFilterTabsDropdown';
import { PermissionsConstants } from '../../utils/permissions.constants';
import { Form } from 'react-bootstrap';
import checklistService from '../../services/checklist.service';
import Skeleton from 'react-loading-skeleton';
import ChecklistCalendar from './ChecklistCalendar';
import { useProfileContext } from '../../contexts/profileContext';
import MaterialIcon from '../../components/commons/MaterialIcon';
import moment from 'moment/moment';

const FILTER_STATUSES = {
  pending: { key: 'pending', label: 'Not Started', clazz: 'pending' },
  inProgress: { key: 'inProgress', label: 'In Progress', clazz: 'progress' },
  overdue: { key: 'overdue', label: 'Overdue', clazz: 'overdue' },
  completed: { key: 'completed', label: 'Completed', clazz: 'completed' },
};

const tabsData = [
  {
    tabId: 2,
    title: FILTER_STATUSES.pending.label,
    key: FILTER_STATUSES.pending.key,
    clazz: FILTER_STATUSES.pending.clazz,
  },
  {
    tabId: 3,
    title: FILTER_STATUSES.inProgress.label,
    key: FILTER_STATUSES.inProgress.key,
    clazz: FILTER_STATUSES.inProgress.clazz,
  },
  {
    tabId: 4,
    title: FILTER_STATUSES.overdue.label,
    key: FILTER_STATUSES.overdue.key,
    clazz: FILTER_STATUSES.overdue.clazz,
  },
  {
    tabId: 5,
    title: FILTER_STATUSES.completed.label,
    key: FILTER_STATUSES.completed.key,
    clazz: FILTER_STATUSES.completed.clazz,
  },
  {
    tabId: 1,
    title: 'All',
    key: 'all',
    clazz: 'all',
  },
];

const Counter = ({ max }) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < max) {
        setCount(count + 1);
      }
    }, 2);

    return () => clearInterval(interval);
  }, [count, max]);

  return <span>{count}</span>;
};
const ChecklistStats = ({ parentFilter, selectedOwner, isFilterCheck }) => {
  const [loading, setLoading] = useState(false);
  const { profileInfo } = useProfileContext();
  const [filterStatuses, setFilterStatuses] = useState([
    {
      id: FILTER_STATUSES.pending.key,
      label: FILTER_STATUSES.pending.label,
      status: FILTER_STATUSES.pending.key,
      index: 0,
    },
    {
      id: FILTER_STATUSES.inProgress.key,
      label: FILTER_STATUSES.inProgress.label,
      status: FILTER_STATUSES.inProgress.key,
      index: 1,
    },
    {
      id: FILTER_STATUSES.overdue.key,
      label: FILTER_STATUSES.overdue.label,
      status: FILTER_STATUSES.overdue.key,
      index: 2,
    },
    {
      id: FILTER_STATUSES.completed.key,
      label: FILTER_STATUSES.completed.label,
      status: FILTER_STATUSES.completed.key,
      index: 3,
    },
  ]);
  const params = {
    page: 1,
    limit: 1,
  };

  const getDateFilters = () => {
    const filters = parentFilter?.isChecklistFilterCheck?.filters || [];
    if (filters.length) {
      return parentFilter?.isChecklistFilterCheck?.filters?.reduce(
        (acc, item) => {
          acc[item.key] = item.value;
          return acc;
        },
        {}
      );
    }
    return {};
  };

  const getOwnerFilter = () => {
    if (selectedOwner?.type) {
      if (selectedOwner.type === 'me') {
        return {
          ownerId: profileInfo?.id,
        };
      } else if (selectedOwner.type === 'other') {
        return {
          ownerId: selectedOwner?.data?.id,
        };
      } else {
        return {};
      }
    }
    return {};
  };
  const loadChecklistStats = async () => {
    const dateFilters = getDateFilters();
    const filterParams = parentFilter?.isChecklistFilterCheck?.name
      ?.toLowerCase()
      .includes('my')
      ? {
          ...params,
          ownerId: profileInfo?.id,
          ...dateFilters,
          ...isFilterCheck?.filter,
        }
      : { ...params, ...dateFilters, ...isFilterCheck?.filter };

    const withOwnerFilter = { ...filterParams, ...getOwnerFilter() };
    const fetchChecklistByStatus = (status) =>
      checklistService.getOrgCheckLists({
        ...withOwnerFilter,
        'organizationChecklistProgress[status]': status,
      });
    setLoading(true);
    const responses = await Promise.all([
      fetchChecklistByStatus(FILTER_STATUSES.pending.key),
      fetchChecklistByStatus(FILTER_STATUSES.inProgress.key),
      fetchChecklistByStatus([
        FILTER_STATUSES.inProgress.key,
        FILTER_STATUSES.pending.key,
      ]),
      fetchChecklistByStatus(FILTER_STATUSES.completed.key),
    ]);

    setFilterStatuses((prevStatuses) =>
      prevStatuses.map((status, index) => ({
        ...status,
        count: responses[index]?.data?.pagination?.count || 0,
      }))
    );
    setLoading(false);
  };
  useEffect(() => {
    loadChecklistStats();
  }, [parentFilter?.isChecklistFilterCheck, selectedOwner, isFilterCheck]);

  return (
    <>
      {loading ? (
        <div className="d-flex p-2 justify-content-center align-items-center ">
          <Skeleton width={120} height={10} />
        </div>
      ) : (
        <div className="stats">
          <ul className="px-3">
            {filterStatuses?.map((item) => (
              <li
                key={item.id}
                className="d-flex fs-7 align-items-center justify-content-center gap-1"
              >
                <label className="mb-0 fs-7 font-weight-normal cursor-pointer">
                  {item.label}
                </label>
                <span className="text-gray-400">•</span>
                <label className="mb-0 font-weight-bold cursor-pointer text-black">
                  <Counter max={item.count} />
                </label>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

const AllChecklist = () => {
  const { tenant } = useTenantContext();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [checklistCreate, setChecklistCreate] = useState(false);
  const [refreshChecklist, setRefreshChecklist] = useState(0);
  const [checklistFilterSelected, setChecklistFilterSelected] = useState({});
  const allStatus = tabsData.at(-1);
  const [getByFilter, setGetByFilter] = useState(allStatus.key);
  const [activeTab, setActiveTab] = useState(allStatus.tabId);
  const [openChecklistFilter, setOpenChecklistFilter] = useState(false);
  const [filterTabs, setFilterTabs] = useState('filters');
  const [checklistFilter, setChecklistFilter] = useState('owners');
  const { profileInfo } = useProfileContext();
  const [checklistFilterOptionSelected, setChecklistFilterOptionSelected] =
    useState({});
  const [isChecklistFilterCheck, setIsChecklistFilterCheck] = useState({});
  const [datesWithDots, setDatesWithDots] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const defaultAllFilter = {
    key: 'MyChecklists',
    name: 'My Checklists',
    filter: { self: true, done: false },
  };
  const [isFilterCheck] = useState({});
  // this is a filter state that we are going to use in three tabs filter owners section
  const [selectedOwner, setSelectedOwner] = useState({
    type: 'me',
    data: profileInfo,
  });
  const [ownershipFilter, setOwnershipFilter] = useState(defaultAllFilter);

  const isFilterMatch = (optionFilter) => {
    // Check if both filters are empty (this ensures only one empty filter can be selected)
    if (
      Object.keys(optionFilter).length === 0 &&
      Object.keys(isFilterCheck.filter || {}).length === 0
    ) {
      return true;
    }

    // Ensure the filters match exactly (length and key-value pairs)
    return (
      Object.keys(optionFilter).length ===
        Object.keys(isFilterCheck.filter || {}).length &&
      Object.keys(optionFilter).every(
        (key) => isFilterCheck.filter?.[key] === optionFilter[key]
      )
    );
  };

  const ownershipOptions = [
    {
      label: `My Checklists`,
      filter: { self: true, done: false },
      key: 'MyChecklists',
      name: 'My Checklists',
      selected: isFilterMatch({ self: true, done: false }),
    },
    {
      label: `All Checklists`,
      filter: {},
      key: 'AllChecklists',
      name: 'All Checklists',
      filters: [
        {
          key: 'organizationChecklistProgress[status]',
          value: ['completed', 'pending', 'inProgress'],
        },
      ],
      selected: isFilterMatch({}),
    },
  ];

  const [, setCheckListCount] = useState({ count: 0 });
  const { setShowModal: setShowCreateChecklist, ChecklistCreateModal } =
    useCreateChecklist(
      () => {
        setRefreshChecklist((prevState) => prevState + 1);
      },
      { setErrorMessage, setSuccessMessage }
    );
  const toggle = (tab) => {
    if (activeTab !== tab.tabId) {
      setActiveTab(tab.tabId);
      setGetByFilter(tab?.key);
    }
  };
  const handleChecklistFilterSelect = (item) => {
    const { key } = item || {};
    const statusConfig = {
      OverDue: tabsData[2],
      Pending: tabsData[0],
      InProgress: tabsData[1],
      Completed: tabsData[3],
      assigned: allStatus,
    };
    setGetByFilter(statusConfig[key]?.key);
    setActiveTab(statusConfig[key]?.tabId);
    setIsChecklistFilterCheck(item);
    setOwnershipFilter(defaultAllFilter);
  };

  const hasDateFilter =
    isChecklistFilterCheck?.filters &&
    isChecklistFilterCheck?.filters.some(
      (filter) =>
        filter.key === 'initialDueDateRange[start]' ||
        filter.key === 'initialDueDateRange[end]'
    );

  const handleOwnerFilterSelect = (item) => {
    setOwnershipFilter(item);
    if (item.key === defaultAllFilter.key) {
      setSelectedOwner({
        type: 'me',
        data: profileInfo,
      });
    } else {
      setSelectedOwner({});
    }
    const itemFilters = Object.hasOwn(item, 'filters') ? item.filters : [];
    if (isChecklistFilterCheck?.filters?.length) {
      const prevFilters = {
        ...item,
        filters: [...isChecklistFilterCheck?.filters, ...itemFilters],
      };
      setIsChecklistFilterCheck(prevFilters);
    }
  };

  const handleDateChange = (newValue) => {
    const startDate = moment(newValue).startOf('day');
    const endDate = moment(newValue).endOf('day');
    const newFilter = { ...isChecklistFilterCheck };
    if (!Object.hasOwn(newFilter, 'filters')) {
      newFilter.filters = [];
    }
    setIsChecklistFilterCheck({
      ...newFilter,
      filters: [
        {
          key: 'initialDueDateRange[start]',
          value: startDate.format(),
        },
        {
          key: 'initialDueDateRange[end]',
          value: endDate.format(),
        },
      ],
    });
  };

  const handleClearDate = () => {
    const newFilter = { ...isChecklistFilterCheck };
    if (newFilter?.filters) {
      newFilter.filters = newFilter?.filters?.filter(
        (filter) =>
          filter.key !== 'initialDueDateRange[start]' &&
          filter.key !== 'initialDueDateRange[end]'
      );
    }
    setIsChecklistFilterCheck(newFilter);
    setSelectedDate(new Date());
  };

  const getCalendarData = async (startDate, endDate) => {
    try {
      const response = await checklistService.getChecklistAggregateStatus({
        self: ownershipFilter?.key?.toLowerCase().includes('my'),
        dueDateRange: {
          start: startDate,
          end: endDate,
        },
        unit: 'day',
      });
      setDatesWithDots(response.data);
    } catch (err) {
      console.error('Error fetching dots data:', err);
    }
  };

  useEffect(() => {
    // refresh calendar when new checklist is created.
    const startDate = moment(selectedDate).startOf('month');
    const endDate = moment(selectedDate).endOf('month');
    getCalendarData(startDate, endDate);
  }, [refreshChecklist, isFilterCheck, ownershipFilter]);

  useEffect(() => {
    // when other than me is selected from filter dropdown left set ownership to All and status to all.
    if (selectedOwner?.type === 'other') {
      setOwnershipFilter(ownershipOptions[1]);
      setActiveTab(allStatus.tabId);
    }
  }, [selectedOwner]);
  return (
    <>
      <AlertWrapper>
        <Alert
          message={errorMessage}
          setMessage={setErrorMessage}
          color="danger"
        />
        <Alert
          message={successMessage}
          setMessage={setSuccessMessage}
          color="success"
        />
      </AlertWrapper>
      <div className="custom-layout checklist-layout">
        <div className="custom-layout-sidebar overflow-x-hidden">
          <ChecklistCalendar
            className="border-bottom calendar-checklist"
            value={selectedDate}
            setSelectedDate={setSelectedDate}
            onChange={handleDateChange}
            datesWithDots={datesWithDots}
            getCalendarData={getCalendarData}
          />
          {hasDateFilter && (
            <div
              className="d-flex align-items-center justify-content-end cursor-pointer pt-2 pr-3 fs-7"
              onClick={handleClearDate}
            >
              <MaterialIcon icon="close" size="fs-5" clazz="d-flex" />
              <span className="date-clear-btn">Clear date</span>
            </div>
          )}

          <div className="d-flex flex-column gap-2 m-3 mt-4 mb-5">
            <h3 className="fs-7 mb-0">Ownership</h3>
            {ownershipOptions?.map((item) => (
              <Form.Check
                key={item.key}
                id={item.key}
                inline
                label={item.label}
                name={item.key}
                className={`large-radio fs-7`}
                type="radio"
                checked={item.key === ownershipFilter.key}
                onChange={() => handleOwnerFilterSelect(item)}
              />
            ))}
          </div>

          <div className="d-flex flex-column gap-2 m-3">
            <h3 className="fs-7 mb-0">Status</h3>
            {tabsData?.map((item) => (
              <Form.Check
                key={item.key}
                id={item.key}
                inline
                label={item.title}
                name={item.key}
                className={`large-radio fs-7 filter-${item.clazz}`}
                type="radio"
                checked={activeTab === item.tabId}
                onChange={() => toggle(item)}
              />
            ))}
          </div>
        </div>
        <div className="custom-layout-content overflow-hidden bg-white">
          <div className="justify-content-between button-pannel d-flex py-3 mx-3 align-items-center">
            <ChecklistFilterTabsDropdown
              options={ChecklistFilters}
              openFilter={openChecklistFilter}
              btnToggleStyle="py-2 btn-sm"
              setOpenFilter={setOpenChecklistFilter}
              filterOptionSelected={
                ownershipOptions
                  .map((o) => o.key)
                  .includes(isChecklistFilterCheck?.key)
                  ? {}
                  : isChecklistFilterCheck
              }
              filterSelected={checklistFilterSelected}
              filterTabs={filterTabs}
              handleFilterSelect={(e, item) => {
                if (item.key === 'all') {
                  setSelectedOwner({});
                } else {
                  setSelectedOwner({
                    type: 'me',
                    data: profileInfo,
                  });
                }
                handleChecklistFilterSelect(item);
              }}
              selectedOwner={selectedOwner}
              setSelectedOwner={setSelectedOwner}
              setFilterOptionSelected={setChecklistFilterOptionSelected}
              setFilterSelected={setIsChecklistFilterCheck}
              setFilterTabs={setFilterTabs}
              onHandleFilterOrg={setChecklistFilter}
              extraClasses={'dropdown-menu-right'}
            />
            <LayoutHead alignTop="mb-0" extraClasses={'all-activities'}>
              <div className="d-flex gap-1 align-items-center">
                {isModuleAllowed(
                  tenant?.modules,
                  PermissionsConstants.Checklist.checklist
                ) && isPermissionAllowed('checklists', 'create') ? (
                  <>
                    <ButtonIcon
                      label="Add Checklist"
                      icon="add"
                      onClick={() => setShowCreateChecklist(true)}
                      classnames="btn-sm px-3"
                      color="primary"
                    />
                  </>
                ) : (
                  ''
                )}
              </div>
            </LayoutHead>
          </div>
          <div className="w-100 overflow-hidden px-0">
            <ActivitiesChecklist
              checklistCreate={checklistCreate}
              refresh={refreshChecklist}
              filterOptionSelected={checklistFilterOptionSelected}
              setFilterOptionSelected={setChecklistFilterOptionSelected}
              filterSelected={checklistFilterSelected}
              activeTabId={getByFilter}
              checklistFilter={checklistFilter}
              selectedOwner={selectedOwner}
              setSelectedOwner={setSelectedOwner}
              isChecklistFilterCheck={isChecklistFilterCheck}
              setFilterSelected={setChecklistFilterSelected}
              setIsChecklistFilterCheck={setIsChecklistFilterCheck}
              setCheckListCount={setCheckListCount}
              setChecklistCreate={setChecklistCreate}
              refreshCalendar={getCalendarData}
              isFilterCheck={isFilterCheck}
              ownershipFilter={ownershipFilter}
              ChecklistStats={ChecklistStats}
            />
          </div>
        </div>
        <ChecklistCreateModal
          setErrorMessage={setErrorMessage}
          setSuccessMessage={setSuccessMessage}
        />
      </div>
    </>
  );
};

export default AllChecklist;
