import AddNewReportButton from './AddNewReportButton';
import Skeleton from 'react-loading-skeleton';
import TooltipComponent from '../lesson/Tooltip';
import ReportOwner from './ReportOwner';
import MaterialIcon from '../commons/MaterialIcon';
import ButtonFilterDropdown from '../commons/ButtonFilterDropdown';
import ButtonIcon from '../commons/ButtonIcon';
import DownloadReportDropdown from './DownloadReportDropdown';
import React from 'react';
import { ReportTypes } from './reports.constants';
import moment from 'moment';

const ReportAction = ({
  readOnly,
  pastReports,
  handleManualReport,
  rptGenerated,
  handleEditReport,
  loadingReport,
  loadingPastReports,
  selectedRpt,
  setSelectedRpt,
  selectedIndividualRpt = {},
  setSelectedIndividualRpt = () => {},
  handleClearIndividualReport = () => {},
  handleEditIndividualReport = () => {},
  profileInfo,
  report,
  startDownload,
  setStartDownload,
  downloadOptions,
  csvData,
  exportToCSV,
  reportType,
  linkConfig,
  draftMode,
  handleDraftClick,
  reSync,
  handleResyncClick,
  organization,
}) => {
  const isCoreData =
    Object.keys(report?.coreData || {}).length || report?.manualInput?.isAuto;

  const isOrgPrimaryOwner =
    profileInfo &&
    profileInfo?.id === organization?.assigned_user_id &&
    selectedRpt?.createdById === organization?.assigned_user_id;

  return (
    <>
      {!readOnly && (
        <div
          className={`d-flex position-absolute align-items-center gap-1 report-controls ${
            readOnly ? 'end-0' : ''
          }`}
          style={{ top: readOnly ? -60 : -35 }}
        >
          {reportType !== ReportTypes.MerchantV2 && (
            <AddNewReportButton
              reports={pastReports}
              readOnly={readOnly}
              handleManualReport={handleManualReport}
              addView={!rptGenerated && !loadingReport && !readOnly}
            />
          )}
        </div>
      )}
      <div
        className={`d-flex align-items-center gap-1 w-100 report-controls ${
          pastReports.length > 0 && !readOnly ? 'pt-3' : ''
        } ${readOnly ? 'pb-0' : ''}`}
        style={{ top: readOnly ? -60 : -42 }}
      >
        {loadingPastReports ? (
          <div className="d-flex align-items-center px-3 pt-3 justify-content-between w-100">
            <div>
              <Skeleton height={12} width={120} />
            </div>
            <div className="d-flex align-items-center ml-auto gap-1 mx-2">
              <Skeleton height={12} width={50} />
              <Skeleton height={12} width={80} />
              <Skeleton height={12} width={50} />
            </div>
          </div>
        ) : (
          <div className="d-flex align-items-end pl-3 pr-2 pt-0 justify-content-between w-100">
            <div>
              <h1
                className={`mb-0 d-flex align-items-center gap-1  ${
                  readOnly ? 'font-size-lg' : 'font-size-em'
                }`}
              >
                <span>{selectedRpt?.prettyDate}</span>
                {selectedRpt?.prettyDate && !readOnly && (
                  <TooltipComponent
                    placement="right"
                    title={
                      <ReportOwner report={selectedRpt} me={profileInfo} />
                    }
                  >
                    <MaterialIcon icon="info" />
                  </TooltipComponent>
                )}
              </h1>
              {reportType === ReportTypes.MerchantV2 &&
              selectedRpt?.coreDataList?.length ? (
                <div className="d-flex align-items-center gap-1">
                  <ButtonFilterDropdown
                    buttonText="Select Month"
                    icon="description"
                    menuClass="rpt-history-dd-width max-h-300"
                    btnToggleStyle="btn-h-sm mt-2"
                    options={selectedRpt?.coreDataList?.sort(
                      (a, b) => moment(a.Month) - moment(b.Month)
                    )}
                    customKeys={['key', 'prettyDate']}
                    filterOptionSelected={selectedIndividualRpt}
                    handleFilterSelect={(e, item) => {
                      setSelectedIndividualRpt(item);
                    }}
                  />
                  {selectedIndividualRpt?.key && (
                    <>
                      <ButtonIcon
                        icon="edit"
                        color="white"
                        label="Edit"
                        onclick={() =>
                          handleEditIndividualReport(selectedIndividualRpt)
                        }
                        classnames="btn-sm position-relative"
                        style={{ top: 4 }}
                      />
                      <div
                        style={{ top: 3 }}
                        className="d-flex position-relative align-items-center cursor-pointer fs-7"
                        onClick={handleClearIndividualReport}
                      >
                        <MaterialIcon icon="close" size="fs-5" clazz="d-flex" />
                        <span className="date-clear-btn">Clear</span>
                      </div>
                    </>
                  )}
                </div>
              ) : null}
            </div>
            <div className="d-flex align-items-center ml-auto gap-1 mx-2">
              {draftMode && !readOnly && (
                <ButtonIcon
                  icon="edit_square"
                  color="outline-primary"
                  label="1 Draft Report"
                  onclick={handleDraftClick}
                  classnames="btn-sm bg-white"
                />
              )}
              {rptGenerated && !readOnly && reSync && isCoreData && (
                <ButtonIcon
                  icon="refresh"
                  color="outline-primary"
                  label="Refresh"
                  onclick={() => handleResyncClick(report)}
                  classnames="btn-sm"
                />
              )}
              {rptGenerated &&
                !readOnly &&
                reportType !== ReportTypes.MerchantV2 &&
                isOrgPrimaryOwner && (
                  <ButtonIcon
                    icon="edit"
                    color="white"
                    label="Edit"
                    onclick={() => handleEditReport('0')}
                    classnames="btn-sm"
                  />
                )}
              {pastReports.length > 0 && (
                <ButtonFilterDropdown
                  buttonText="Select Date"
                  icon="description"
                  menuClass="rpt-history-dd-width max-h-300"
                  btnToggleStyle="btn-h-sm"
                  options={pastReports}
                  customKeys={['key', 'prettyDate']}
                  filterOptionSelected={selectedRpt}
                  handleFilterSelect={(e, item) => {
                    setSelectedRpt(item);
                  }}
                />
              )}

              {readOnly ? (
                <>
                  {(rptGenerated || pastReports.length > 0) && (
                    <>
                      {rptGenerated && (
                        <DownloadReportDropdown
                          report={report}
                          selectedRpt={selectedRpt}
                          startDownload={startDownload}
                          setStartDownload={setStartDownload}
                          downloadOptions={downloadOptions}
                          csvData={csvData}
                          reportType={reportType}
                          exportToCSV={exportToCSV}
                          linkConfig={linkConfig}
                        />
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  {reportType === ReportTypes.Merchant ||
                  reportType === ReportTypes.MerchantV2 ? (
                    <>
                      {rptGenerated && (
                        <DownloadReportDropdown
                          report={report}
                          startDownload={startDownload}
                          setStartDownload={setStartDownload}
                          downloadOptions={downloadOptions}
                          csvData={csvData}
                          reportType={reportType}
                          exportToCSV={exportToCSV}
                          linkConfig={linkConfig}
                        />
                      )}
                    </>
                  ) : null}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ReportAction;
