import React, { useCallback, useContext, useMemo, useState } from 'react';
import DeleteConfirmationModal from '../../../components/modal/DeleteConfirmationModal';
import { AlertMessageContext } from '../../../contexts/AlertMessageContext';
import ReportService from '../../../services/report.service';
import { getNAICSWithIndustry, overflowing } from '../../../utils/Utils';
import {
  ActionTypes,
  EngagementReportConfig,
  MerchantReportV2Sections,
  ReportTypes,
} from '../../../components/reports/reports.constants';
import {
  getReportName,
  MerchantReportAggregateData,
} from '../../../components/reports/reports.helper.functions';
import MaterialIcon from '../../../components/commons/MaterialIcon';
import _ from 'lodash';
import {
  APWData,
  ARRWData,
  FBCOWData,
  FBCWData,
  OVWData,
  RecommendationsDidYouKnowData,
  RefundAndChargebacksAmountProcessedData,
  RefundAndChargebacksOverviewData,
  WidgetTypes,
} from '../../../components/reportbuilder/constants/widgetsConstants';

const PageConfig = EngagementReportConfig[ReportTypes.MerchantV2].PageConfig;

const SyncMerchantReportModal = ({
  show,
  setShow,
  report,
  organization,
  handleGenerateReport,
  setParentModalShow,
}) => {
  const { setSuccessMessage, setErrorMessage } =
    useContext(AlertMessageContext);

  const getDefaultReportPages = () => {
    return _.cloneDeep(PageConfig);
  };
  const [loader, setLoader] = useState(false);

  const getDefaultWidgets = (reportObject = {}) => {
    return {
      [MerchantReportV2Sections.CardPaymentAcceptanceOverview]: [
        {
          id: 1,
          widgetConfig: {
            ...APWData,
            data: reportObject,
          },
          type: WidgetTypes.APW,
        },
        {
          id: 2,
          widgetConfig: {
            ...OVWData,
            data: reportObject,
          },
          type: WidgetTypes.OVW,
        },
      ],
      [MerchantReportV2Sections.FeeBreakdown]: [
        {
          id: 1,
          widgetConfig: { ...FBCWData, reportObject },
          type: WidgetTypes.FBCW,
        },
        {
          id: 2,
          widgetConfig: FBCOWData,
          type: WidgetTypes.OVW,
        },
      ],
      [MerchantReportV2Sections.RefundsAndChargebacks]: [
        {
          id: 1,
          widgetConfig: {
            ...RefundAndChargebacksAmountProcessedData,
            data: reportObject,
          },
          type: WidgetTypes.APW,
        },
        {
          id: 2,
          widgetConfig: RefundAndChargebacksOverviewData,
          type: WidgetTypes.OVW,
        },
      ],
      [MerchantReportV2Sections.Recommendations]: [
        {
          id: 1,
          widgetConfig: RecommendationsDidYouKnowData,
          type: WidgetTypes.OVW,
        },
      ],
      [MerchantReportV2Sections.YourDedicatedTeam]: [
        {
          id: 1,
          widgetConfig: { ...reportObject },
          type: WidgetTypes.TCW,
        },
        {
          id: 2,
          widgetConfig: ARRWData,
          type: WidgetTypes.ARRW,
        },
      ],
    };
  };

  const createManualReportFromCoreData = (coreDataObject, coreDataReports) => {
    const aggregate = MerchantReportAggregateData(coreDataReports);

    const reportMapped = {
      companyName: aggregate.Customer_Name || organization.name, // 0
      reportDate: aggregate.monthRange.start, // 4
      aggregate,
      coreData: coreDataReports.map((dt) => ({
        ...dt,
        feId: crypto.randomUUID(),
      })),
    };

    const defaultWidgets = getDefaultWidgets(reportMapped);
    const defaultPages = getDefaultReportPages();

    return {
      name: reportMapped.companyName,
      date: reportMapped.reportDate,
      type: ReportTypes.Merchant,
      manualInput: {
        ...reportMapped,
        widgets: defaultWidgets,
        reportPages: defaultPages,
        ...getNAICSWithIndustry(organization),
        isAuto: true, // track whether this was generated by core
      },
    };
  };

  const handleConfirmDeleteReport = async () => {
    try {
      setLoader(true);
      // first get new data by calling core data api
      const customerId = organization.customer_id;
      const coreDataReports = await ReportService.getReportsAggregateData({
        compositeId: customerId,
        type: ReportTypes.Merchant,
      });
      if (coreDataReports.length) {
        const latestReport = coreDataReports.at(-1); // pick the last one for now
        const newCoreData = createManualReportFromCoreData(
          latestReport,
          coreDataReports
        );
        await ReportService.updateReport(report.reportId, newCoreData);
        overflowing();
        setParentModalShow(false);
        setShow(false);
        handleGenerateReport(report, ActionTypes.UPDATE, {
          ...newCoreData,
          reportId: report.reportId,
          key: report.reportId,
        });
      } else {
        setErrorMessage('No data found.');
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoader(false);
    }
  };

  const SyncModalBody = ({ text }) => {
    return (
      <div>
        <div className="d-flex justify-content-center align-items-center">
          <MaterialIcon rounded icon="refresh" clazz="font-size-4em" />
        </div>
        <hr />
        <h4>{text}</h4>
        <ul className="list-disc">
          {[{ ...report, title: getReportName(report) }].map((item) => (
            <li className="font-weight-medium ml-4" key={item?.id}>
              <p className="mb-1">{item?.title}</p>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  return (
    <DeleteConfirmationModal
      showModal={show}
      setShowModal={setShow}
      setSelectedCategories={() => {}}
      customEvent={handleConfirmDeleteReport}
      loader={loader}
      customBody={
        <SyncModalBody text="Are you sure you want to refresh this Report?" />
      }
      itemsConfirmation={[{ ...report, title: getReportName(report) }]}
      description="Are you sure you want to refresh this Report?"
      itemsReport={[]}
      setErrorMessage={setErrorMessage}
      setSuccessMessage={setSuccessMessage}
      positiveBtnText="Yes, Refresh"
      positiveBtnIcon="refresh"
    />
  );
};

const useResyncMerchantReportModal = (
  handleGenerateReport,
  setParentModalShow
) => {
  const [showModal, setShowModal] = useState(false);
  const [report, setReport] = useState('');
  const [organization, setOrganization] = useState('');

  const SyncMerchantReportModalModalCallback = useCallback(() => {
    return (
      <SyncMerchantReportModal
        show={showModal}
        setShow={setShowModal}
        report={report}
        organization={organization}
        handleGenerateReport={handleGenerateReport}
        setParentModalShow={setParentModalShow}
      />
    );
  }, [
    showModal,
    setShowModal,
    report,
    setReport,
    organization,
    setOrganization,
  ]);

  return useMemo(
    () => ({
      setShowModal,
      setReport,
      setOrganization,
      SyncMerchantReportModal: SyncMerchantReportModalModalCallback,
    }),
    [setShowModal, SyncMerchantReportModalModalCallback]
  );
};

export default useResyncMerchantReportModal;
