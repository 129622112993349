import { useEffect, useState } from 'react';
import Avatar from '../../../components/Avatar';
import GenericTable from '../../../components/GenericTable';
import { ShortDescription } from '../../../components/ShortDescription';
import { cubeService } from '../../../services';
import TableSkeleton from '../../../components/commons/TableSkeleton';
const LessonLeaderboard = ({
  dateRange = {},
  reportLimit = {},
  setShowCSVBtn = () => {},
  queryFilter,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState(['', 'ASC']);

  const dateFilter = Object.keys(dateRange)?.length > 0 && dateRange;
  useEffect(() => {
    (async () => {
      setLoading(true);
      const results = await cubeService.loadCustomAnalytics({
        type: 'Leaderboard',
        orderBy: orderBy?.order,
        ...(reportLimit?.key !== 'all'
          ? { limit: parseInt(reportLimit.key) }
          : {}),
        ...(queryFilter?.filters?.length > 0
          ? { teams: queryFilter?.filters }
          : {}),
        ...(Object.keys(dateRange)?.length > 0
          ? dateRange?.key === 'All Time'
            ? {}
            : { startDate: dateRange?.startDate, endDate: dateRange?.endDate }
          : {}),
      });
      setData(results);
      setShowCSVBtn(results?.length > 0);
      setLoading(false);
    })();
  }, [dateFilter, reportLimit, queryFilter, orderBy]);
  const updatedRows = data?.map((item) => {
    return {
      users: `${item?.first_name} ${item?.last_name}`,
      completed: item?.completed,
      selfDirected: item?.completed - item?.taken_assigned,
      team: item?.teams,
      assigned: item?.taken_assigned,
    };
  });

  const rows = data?.map((result, index) => {
    const user = {
      firstName: result?.first_name,
      lastName: result?.last_name,
      avatar: result?.avatar,
    };
    const name = `${result?.first_name} ${result?.last_name}`;
    const rank = index + 1;

    return {
      id: index,
      dataRow: [
        {
          key: 'rank',
          component: (
            <div className="rank-container">
              <span className={`rank-${rank} font-weight-semi-bold`}>
                {rank}
              </span>
            </div>
          ),
        },
        {
          key: 'users',
          component: (
            <div className={`d-flex align-items-center font-weight-semi-bold`}>
              <Avatar defaultSize="xs" classModifiers="mr-2" user={user} />
              {name}
            </div>
          ),
        },
        {
          key: 'completed',
          component: (
            <div>
              <span>{result?.completed}</span>
            </div>
          ),
        },
        {
          key: 'assigned',
          component: result?.taken_assigned,
        },
        {
          key: 'selfDirected',
          component: result?.completed - result?.taken_assigned,
        },
        {
          key: 'team',
          component: (
            <span className="d-flex align-items-center">
              <ShortDescription limit={20} content={result?.teams} />
            </span>
          ),
        },
      ],
    };
  });

  const loader = () => {
    if (loading) return <TableSkeleton cols={6} rows={10} />;
  };
  const handleSortByData = (key) => {
    if (key?.name?.key === 'selfDirected') {
      setOrderBy({
        order: `${key?.name?.orderBy} ${
          key?.name?.clicked.toLowerCase() === 'desc' ? 'asc' : 'desc'
        }`,
      });
    } else {
      setOrderBy({
        order: `${key?.name?.orderBy} ${key?.name?.clicked.toLowerCase()}`,
      });
    }
    setOrder([key?.name?.orderBy, key?.name?.clicked]);
  };

  return (
    <div>
      <div>
        {loading ? (
          loader()
        ) : (
          <GenericTable
            checkbox={false}
            data={rows}
            exportToCSV={true}
            tableData={updatedRows}
            fileName="LeaderboardReport"
            columns={[
              {
                key: 'rank',
                component: 'Rank',
                width: '5%',
              },
              {
                key: 'users',
                component: 'User',
                width: '15.8%',
              },
              {
                orderBy: 'completed',
                key: 'completed',
                component: 'Total Completed',
                tooltipTitle: '# of mandatory and self study lessons completed',
                width: '15.8%',
              },
              {
                orderBy: 'taken_assigned',
                key: 'assigned',
                component: 'Completed Assigned',
                tooltipTitle: '# of assigned lessons completed',
                width: '15.8%',
              },
              {
                orderBy: 'taken_assigned',
                key: 'selfDirected',
                component: 'Completed Self Study',
                tooltipTitle: '# of self study lessons completed',
                width: '15.8%',
              },
              {
                key: 'team',
                component: 'Team Name',
                width: '15.8%',
              },
            ]}
            usePagination={false}
            noDataInDbValidation={true}
            sortingTable={handleSortByData}
            sortingOrder={order}
          />
        )}
      </div>
    </div>
  );
};

export default LessonLeaderboard;
