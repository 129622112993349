import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import LessonDetailsReport from './LessonDetailsReport';
import ButtonFilterDropdown from '../../../components/commons/ButtonFilterDropdown';
import { reportPages } from '../../../utils/constants';
import teamsService from '../../../services/teams.service';
import ReportSearchOptionDropdown from '../../../components/commons/ReportSearchOptionDropdown';
import { ReportSkeletonLoader } from '../../Insights/ReportSkeletonLoader';
import { cubeService } from '../../../services';

const TrainingLeassonDetails = ({
  dashboard,
  selectedComponent = {},
  componentHeight = 'h-100',
}) => {
  const [reportPage, setReportPage] = useState(reportPages[0]);
  const [data, setData] = useState([]);
  const [teams, setTeams] = useState([]);
  const [csvBtnLoad, setShowCSVBtn] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(2);
  const [queryFilter, setQueryFilter] = useState({
    filters: [],
  });
  useEffect(() => {
    (async () => {
      const { data } = await teamsService.getTeams({
        page: 1,
        limit: 100,
        filter: { search: searchQuery, isActive: true },
      });
      setTeams(data);
    })();
  }, [searchQuery]);

  const handleOptionSelect = (event) => {
    const { value } = event.target;
    if (selectedItem?.length > 0) {
      setQueryFilter({
        filters: [...selectedItem],
      });
    } else {
      setQueryFilter((prevState = []) => {
        const isSelected = prevState.includes(value);
        const updatedSelectedData = isSelected
          ? prevState.filter((item) => item !== value)
          : [...prevState, value];
        return updatedSelectedData;
      });
    }
  };
  const handleAllSelect = () => {
    const allSelected = teams?.map((rpt) => rpt?.id);

    setQueryFilter({
      filters: [...allSelected],
    });
  };

  const getData = async (type) => {
    setLoading(true);
    try {
      const results = await cubeService.loadCustomAnalytics({
        type,
        ...(reportPage?.key !== 'all'
          ? { limit: parseInt(reportPage.key) }
          : {}),
      });
      setShowCSVBtn(results?.length > 0);
      setData(results);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData('LessonDetails');
  }, [queryFilter, reportPage]);
  return (
    <Card className={`overflow-x-hidden overflow-y-auto ${componentHeight}`}>
      <CardHeader>
        <div
          className={`d-flex justify-content-between align items center w-100 ${
            csvBtnLoad ? 'mr-5' : ''
          } 0`}
        >
          <h4 className="card-title text-hover-primary mb-0 pt-2 pb-2">
            {dashboard?.name}
          </h4>
          <div
            className={`d-flex align-items-center ${
              csvBtnLoad > 0 ? 'mr-5 pr-5' : ''
            }`}
          >
            <ReportSearchOptionDropdown
              selectedData={selectedItem}
              data={teams}
              show={show}
              label={`Team`}
              scrollable="pr-3 text-left"
              setShow={setShow}
              showCheckAll={false}
              setSelectedData={setSelectedItem}
              search={true}
              customKey={['name', 'id']}
              handleAllSelect={handleAllSelect}
              customStyle={{ width: '320px', marginLeft: '10px' }}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              handleOptionSelected={handleOptionSelect}
            />
            <ButtonFilterDropdown
              buttonText="Timeline"
              options={reportPages}
              filterOptionSelected={reportPage}
              handleFilterSelect={(e, item) => {
                setReportPage(item);
              }}
              btnToggleStyle={`btn-md ml-2`}
            />
          </div>
        </div>
      </CardHeader>
      <CardBody>
        {loading ? (
          <div className="w-100 d-block px-1 py-3">
            <ReportSkeletonLoader rows={15} />
          </div>
        ) : (
          <LessonDetailsReport
            data={data}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default TrainingLeassonDetails;
