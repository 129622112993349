import Alert from '../../Alert/Alert';
import AlertWrapper from '../../Alert/AlertWrapper';
import React, { useEffect, useState } from 'react';
import ReportDragDrop from '../../reports/ReportDragDrop';
import GenerateWorkingCapitalReportModal from '../../reports/GenerateWorkingCapitalReportModal';
import naicsService from '../../../services/naics.service';
import OrganizationService from '../../../services/organization.service';
import {
  ActionTypes,
  EngagementReportConfig,
  getVideoUrlWithTenant,
  ReportTypes,
  WorkingCapitalReportSections,
} from '../../reports/reports.constants';
import ReportCover from '../../reports/ReportCover';
import {
  getNAICSWithIndustry,
  getNaicsWithTitle,
  overflowing,
} from '../../../utils/Utils';
import ReportService from '../../../services/report.service';
import ReportBlocksSkeleton from '../../loaders/ReportBlocksSkeleton';
import ReportDropdownItem from '../../reports/ReportDropdownItem';
import NoDataFound from '../../commons/NoDataFound';
import {
  getCycleDate,
  getReportName,
  workingCapitalMapping,
} from '../../reports/reports.helper.functions';
import { useProfileContext } from '../../../contexts/profileContext';
import {
  APOData,
  AROData,
  DPOFTIData,
  DPOTPData,
  DSOFTIData,
  DSOTPData,
  ICFData,
  TODDData,
  UWCData,
  WidgetTypes,
} from '../../reportbuilder/constants/widgetsConstants';
import WidgetWithActionButtons from '../../reportbuilder/widgets/WidgetWithActionButtons';
import { ListGroup } from 'reactstrap';
import useWidgetsLibrary from '../../reportbuilder/widgets/useWidgetsLibrary';
import _ from 'lodash';
import useIsTenant from '../../../hooks/useIsTenant';
import ReportPages from '../../reportbuilder/ReportPages';
import ReportPDFWrapper from '../../reportbuilder/ReportPDFWrapper';
import {
  defaultGlossaryWorkingCapital,
  NO_REPORTS_AVAILABLE,
  NO_REPORTS_AVAILABLE_ICON,
} from '../../../utils/constants';
import { useTenantContext } from '../../../contexts/TenantContext';
import ReportAction from '../../reports/ReportAction';
import moment from 'moment';
import useHash from '../../../hooks/useHash';
import DisclaimerWidget from '../../reportbuilder/widgets/horizontal/DisclaimerWidget';

const PageConfig =
  EngagementReportConfig[ReportTypes.WorkingCapital].PageConfig;

const DOWNLOAD_OPTIONS = [
  {
    id: 1,
    icon: 'picture_as_pdf',
    key: 'downloadAsPdf',
    name: 'PDF Download',
  },
];

const WorkingCapitalReportSectionKeys =
  EngagementReportConfig[ReportTypes.WorkingCapital].SectionKeys;

const WorkingCapitalAnalysisReport = ({
  organization,
  getProfileInfo,
  readOnly,
  selectedTenant,
}) => {
  const { hash, updateHash } = useHash();
  const getDefaultReportPages = () => {
    return _.cloneDeep(PageConfig);
  };
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [rptGenerated, setRptGenerated] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const [report, setReport] = useState({});
  const [pastReports, setPastReports] = useState([]);
  const [loadingPastReports, setLoadingPastReports] = useState(false);
  const [selectedRpt, setSelectedRpt] = useState({});
  const [startDownload, setStartDownload] = useState(false);
  const [openGenerateReport, setOpenGenerateReport] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [, setLoaderInsights] = useState(false);
  const [insightsData, setInsightsData] = useState({});
  const { profileInfo } = useProfileContext();
  const [widgets, setWidgets] = useState({});
  const {
    setShowModal: setShowWidgetsLibrary,
    WidgetsLibraryModal,
    setSelectedWidget,
  } = useWidgetsLibrary();
  const [reportPages, setReportPages] = useState(getDefaultReportPages());
  const { isExcelBank } = useIsTenant();
  const { tenant } = useTenantContext();
  const APOHeadings = {
    [APOData.extraType]: APOData.heading,
    [AROData.extraType]: AROData.heading,
  };

  const FTIData = {
    DPO: DPOFTIData,
    DSO: DSOFTIData,
  };

  const PowerPotentialData = {
    DPO: DPOTPData,
    DSO: DSOTPData,
  };

  const getHeading = (wg, config) => {
    return wg.type === WidgetTypes.APO
      ? APOHeadings[config.extraType]
      : config.heading;
  };

  const getDescription = (wg, config) => {
    const description = config.description;
    if (wg.type === WidgetTypes.QRCode) {
      return UWCData.description;
    }
    if (wg.type === WidgetTypes.TODD) {
      return TODDData.description;
    }
    return description;
  };

  const getValueText = (wg, config) => {
    return config.extraType === 'DPO' ? APOData.valueText : AROData.valueText;
  };

  const getFactors = (wg, config) => {
    const defaultFactors = FTIData[config.extraType]?.factors;
    return config.factors.map((f) => {
      const oldFactor = defaultFactors.find(
        (of) => of.heading.toLowerCase() === f.heading.toLowerCase()
      );
      return {
        ...f,
        heading: oldFactor.heading,
        icon: oldFactor?.icon || f.icon,
        width: oldFactor?.width,
      };
    });
  };

  const getPowerAndPotential = (wg, config) => {
    return PowerPotentialData[config.extraType]?.powerAndPotential;
  };

  const updateDefaultWidgetsWithMapping = (
    defaultOrSavedWidgets,
    reportObjectWithMapping
  ) => {
    const updatedWidgets = {};
    delete reportObjectWithMapping.widgets;
    delete reportObjectWithMapping.reportPages;
    for (const key in defaultOrSavedWidgets) {
      updatedWidgets[key] = defaultOrSavedWidgets[key]?.map((wg) => ({
        ...wg,
        widgetConfig: {
          ...wg.widgetConfig,
          heading: getHeading(wg, wg.widgetConfig),
          description: getDescription(wg, wg.widgetConfig),
          data: wg.type === WidgetTypes.QRCode ? null : reportObjectWithMapping,
          extraPadding: 'py-0', // QRCode widget reads so...
          factors:
            wg.type === WidgetTypes.FTI
              ? getFactors(wg, wg.widgetConfig)
              : null,
          powerAndPotential:
            wg.type === WidgetTypes.OTP
              ? getPowerAndPotential(wg, wg.widgetConfig)
              : null,
          valueText:
            wg.type === WidgetTypes.APO
              ? getValueText(wg, wg.widgetConfig)
              : null,
          videoDuration:
            wg.type === WidgetTypes.QRCode ? UWCData.videoDuration : null,
          videoId:
            wg.type === WidgetTypes.QRCode
              ? UWCData.videoId
              : wg.widgetConfig.videoId,
        },
      }));
    }

    return {
      ...updatedWidgets,
      ...defaultGlossaryWidget,
    };
  };

  const defaultGlossaryWidget = {
    [WorkingCapitalReportSections.Glossary]: [
      {
        id: 1,
        widgetConfig: {
          glossary: defaultGlossaryWorkingCapital,
          data: { id: 'gloss' },
        },
        type: WidgetTypes.GLOSS,
      },
    ],
  };

  const getDefaultWidgets = (reportObject) => {
    return {
      ...widgets,
      [WorkingCapitalReportSections.Overview]: [
        {
          id: 1,
          widgetConfig: { ...ICFData, data: reportObject },
          type: WidgetTypes.ICF,
        },
        {
          id: 2,
          widgetConfig: { ...TODDData, data: reportObject },
          type: WidgetTypes.TODD,
        },
        {
          id: 3,
          widgetConfig: {
            ...UWCData,
            data: null,
            extraPadding: 'py-5',
          },
          type: WidgetTypes.QRCode,
        },
      ],
      [WorkingCapitalReportSections.Payables]: [
        {
          id: 1,
          widgetConfig: { ...APOData, data: reportObject },
          type: WidgetTypes.APO,
        },
        {
          id: 2,
          widgetConfig: { ...DPOTPData, data: reportObject },
          type: WidgetTypes.OTP,
        },
        {
          id: 3,
          widgetConfig: { ...DPOFTIData, data: reportObject },
          type: WidgetTypes.FTI,
        },
      ],
      [WorkingCapitalReportSections.Receivables]: [
        {
          id: 1,
          widgetConfig: { ...AROData, data: reportObject },
          type: WidgetTypes.APO,
        },
        {
          id: 2,
          widgetConfig: { ...DSOTPData, data: reportObject },
          type: WidgetTypes.OTP,
        },
        {
          id: 3,
          widgetConfig: { ...DSOFTIData, data: reportObject },
          type: WidgetTypes.FTI,
        },
      ],
      ...defaultGlossaryWidget,
    };
  };

  const getReports = async (dontSelect) => {
    setLoadingPastReports(true);
    try {
      const { data } = await OrganizationService.getReports(organization.id, {
        limit: 100,
        page: 1,
        type: ReportTypes.WorkingCapital,
      });

      const reports = data.data
        .map((rpt) => {
          const rptObject = rpt.manualInput;
          const reportWidgets = Object.hasOwn(rptObject, 'widgets')
            ? rptObject.widgets
            : getDefaultWidgets(rptObject);
          return {
            key: rpt.reportId,
            reportId: rpt.reportId,
            customElement: (
              <ReportDropdownItem
                item={{
                  ...rptObject,
                  key: rpt.reportId,
                  reportId: rpt.reportId,
                }}
              />
            ),
            name: getReportName(rptObject),
            isManual: true,
            createdById: rpt.createdById,
            createdAt: rpt.createdAt,
            updatedAt: rpt.updatedAt,
            prettyDate: moment(rptObject.reportDate).format('MMMM YYYY'),
            widgets: reportWidgets,
          };
        })
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

      setPastReports(reports);

      if (!dontSelect) {
        const firstReport = reports.length
          ? hash?.includes('/new')
            ? {}
            : reports[0]
          : {};
        // if we have the reportId then get it
        if (firstReport?.key || hash?.includes('/id')) {
          // we got the id from url now we want to find that in our reports array and get full object
          const urlHashId = hash?.split('/')?.at(-1); // get the last index which has report id
          const reportFound = reports.find((rp) => rp.reportId === urlHashId);
          if (reportFound) {
            setSelectedRpt(reportFound);
          } else {
            setSelectedRpt(firstReport);
          }
        } else {
          setSelectedRpt({});
          setRptGenerated(false);
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingPastReports(false);
    }
  };

  // have to do that to support old generated reports
  const updateExpenseIcons = (rpt) => {
    const expenses = rpt?.loanOverview?.expenses || [];
    const icons = {
      peakUtilization: 'arrow_circle_up',
      lowUtilization: 'arrow_circle_down',
      totalInterestExpense: 'monetization_on',
    };
    return expenses.map((exp) => ({
      ...exp,
      icon: icons[exp.key] || exp.icon,
    }));
  };

  const displayValuesInView = (data, manualInput, insightsData) => {
    const mapping = workingCapitalMapping(manualInput, insightsData);
    const newExpenses = updateExpenseIcons(manualInput);
    const updatedReport = {
      ...manualInput,
      loanOverview: { ...manualInput.loanOverview, expenses: newExpenses },
    };
    const newReport = {
      reportId: data.reportId,
      createdById: data.createdById,
      companyName: data.name,
      valueNaicsSic: manualInput.valueNaicsSic,
      ...updatedReport,
      ...mapping,
      reportDate: getCycleDate(data.date),
      valueN: getNaicsWithTitle(manualInput, organization),
    };
    setReport(newReport);
    setRptGenerated(true);
    return newReport;
  };
  const getReportById = async (selectedReport) => {
    setLoadingReport(true);
    setRptGenerated(false);
    try {
      const defaultReportPages = getDefaultReportPages();
      const data = await ReportService.getReport(selectedReport.key);
      const { manualInput } = data;
      if (manualInput.valueNaicsSic || organization.naics_code) {
        getInsights(
          manualInput.valueNaicsSic || organization.naics_code,
          (newInsightsData) => {
            const rptObject = displayValuesInView(
              data,
              manualInput,
              newInsightsData
            );
            if (Object.hasOwn(manualInput, 'widgets')) {
              // backup if widgets are saved empty
              if (_.isEmpty(manualInput.widgets)) {
                setWidgets(getDefaultWidgets(manualInput));
              } else {
                setWidgets(
                  updateDefaultWidgetsWithMapping(
                    manualInput.widgets,
                    rptObject
                  )
                );
              }
            } else {
              setWidgets(getDefaultWidgets(manualInput));
            }
            // if we have .reportPages saved in report then load those otherwise load default ones to cater old reports too
            if (Object.hasOwn(manualInput, 'reportPages')) {
              setReportPages(manualInput.reportPages);
            } else {
              setReportPages(defaultReportPages);
            }

            if (hash?.includes('/edit')) {
              handleEditReport();
            }
          }
        );
      } else {
        const rptObject = displayValuesInView(data, manualInput, {});
        if (Object.hasOwn(rptObject, 'widgets')) {
          setWidgets(
            updateDefaultWidgetsWithMapping(rptObject.widgets, rptObject)
          );
        } else {
          setWidgets(getDefaultWidgets(rptObject));
        }
        // if we have .reportPages saved in report then load those otherwise load default ones to cater old reports too
        if (Object.hasOwn(rptObject, 'reportPages')) {
          setReportPages(rptObject.reportPages);
        } else {
          setReportPages(defaultReportPages);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleManualReport = () => {
    setSelectedRpt({});
    setIsEdited(false);
    setRptGenerated(false);
    setWidgets(getDefaultWidgets({}));
    setReportPages(getDefaultReportPages());
  };
  const handleEditReport = () => {
    setIsEdited(true);
    setOpenGenerateReport(true);
    updateHash('');
  };

  const getInsights = async (newNaics, callback) => {
    setLoaderInsights(true);
    try {
      let data = {};
      const naicsCode =
        newNaics || report?.valueNaicsSic || organization.naics_code;
      if (naicsCode) {
        let insightsDataRpmgSp = {};
        // get rpmg/sp summary by naics if company has it
        const naicsFirstTwo = naicsCode.slice(0, 2);
        try {
          data = await Promise.all([
            naicsService.getNaicsRpmgSummary(naicsFirstTwo),
            naicsService.getNaicsSpSummary(naicsFirstTwo),
          ]);
          insightsDataRpmgSp = {
            rpmg: data[0],
            sp: data[1],
          };
        } catch (e) {
          insightsDataRpmgSp = {};
        }
        setInsightsData(insightsDataRpmgSp);
        callback(insightsDataRpmgSp);
      } else {
        data = await OrganizationService.getInsightsByOrganization(
          organization.id
        );
        setInsightsData(data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingReport(false);
      setRptGenerated(true);
      setLoaderInsights(false);
    }
  };

  const handleGenerateReport = (
    newReport,
    action,
    newOrUpdatedReport,
    newWidgets,
    newReportPages
  ) => {
    if (action === ActionTypes.REMOVE) {
      const newReports = [
        ...pastReports.filter((rpt) => rpt.key !== newReport.key),
      ];
      const reportsAvailable = newReports.length > 0;
      setPastReports(reportsAvailable ? newReports : []);
      setSelectedRpt(reportsAvailable ? newReports[0] : {});
      setReportPages(getDefaultReportPages());
      setWidgets(getDefaultWidgets({}));
      setRptGenerated(false);
      setOpenGenerateReport(false);
    } else {
      // setRptGenerated(true);
      setReportPages(newReportPages);
      if (newReport.valueNaicsSic || organization.naics_code) {
        getInsights(
          newReport.valueNaicsSic || organization.naics_code,
          (newInsightsData) => {
            if (newReport?.valueNaicsSic !== organization.naics_code) {
              getProfileInfo && getProfileInfo();
            }

            const mapping = workingCapitalMapping(newReport, newInsightsData);
            setOpenGenerateReport(false);

            setRptGenerated(true);
            if (newReport) {
              try {
                if ('key' in selectedRpt) {
                  const newReports = [
                    ...pastReports.map((rpt) =>
                      rpt.key === selectedRpt.key
                        ? {
                            ...rpt,
                            customElement: (
                              <ReportDropdownItem
                                item={{
                                  ...newReport,
                                  key: rpt.key,
                                  reportId: rpt.key,
                                }}
                              />
                            ),
                            name: getReportName(newReport),
                            createdById: profileInfo.id,
                            updatedAt: new Date().toISOString(),
                            prettyDate: moment(newReport?.reportDate).format(
                              'MMMM YYYY'
                            ),
                            widgets: updateDefaultWidgetsWithMapping(
                              newWidgets,
                              mapping
                            ),
                          }
                        : rpt
                    ),
                  ];
                  setPastReports(newReports);
                  const reportMapping = {
                    ...report,
                    ...mapping,
                  };
                  setReport(reportMapping);
                  const selected = newReports.find(
                    (r) => r.key === selectedRpt.key
                  );
                  setSelectedRpt(selected);
                  setWidgets(
                    updateDefaultWidgetsWithMapping(
                      selected.widgets,
                      reportMapping
                    )
                  );
                } else {
                  const rptObject = newReport;
                  const pastReportObject = {
                    key: newOrUpdatedReport.reportId,
                    reportId: newOrUpdatedReport.reportId,
                    customElement: (
                      <ReportDropdownItem
                        item={{
                          ...rptObject,
                          key: newOrUpdatedReport.reportId,
                          reportId: newOrUpdatedReport.reportId,
                        }}
                      />
                    ),
                    name: getReportName(rptObject),
                    isManual: true,
                    createdById: profileInfo.id,
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                    prettyDate: moment().format('MMMM YYYY'),
                  };
                  const newReports = [pastReportObject].concat([
                    ...pastReports,
                  ]);
                  setPastReports(newReports);
                  setSelectedRpt(pastReportObject);
                  setWidgets(
                    updateDefaultWidgetsWithMapping(
                      pastReportObject.widgets,
                      mapping
                    )
                  );
                }
              } catch (e) {
                console.log(e);
              } finally {
                setLoadingReport(false);
                setOpenGenerateReport(false);
              }
            }
          }
        );
      } else {
        setErrorMessage('Please select naics code.');
      }
    }
  };

  const handleGenerateManuallyReport = () => {
    const newReport = {
      companyName: organization.name,
      reportDate: getCycleDate(new Date().toISOString()),
      ...getNAICSWithIndustry(organization),
    };
    setReport(newReport);
    setWidgets(getDefaultWidgets(newReport));
    setReportPages(getDefaultReportPages());
    setOpenGenerateReport(true);
  };

  useEffect(() => {
    if (organization?.naics_code) {
      setReport({
        ...report,
        companyName: organization.name,
        ...getNAICSWithIndustry(organization),
      });
    }
  }, [organization?.naics_code]);

  useEffect(() => {
    if (organization?.id) {
      setReport({
        ...report,
        companyName: organization.name,
        ...getNAICSWithIndustry(organization),
      });
      getReports();
    }
  }, [organization?.id]);

  useEffect(() => {
    if (report?.valueNaicsSic) {
      getInsights(report?.valueNaicsSic);
    }
  }, [report?.valueN, report?.valueNaicsSic]);

  useEffect(() => {
    if (selectedRpt?.key || hash?.includes('/id')) {
      getReportById(selectedRpt);
    }
  }, [selectedRpt?.key]);

  useEffect(() => {
    // look hash url if it contains new open a add modal.
    if (hash?.includes('/new')) {
      handleManualReport();
    }
  }, [hash]);

  const onAddWidget = async (e, newWidget, oldWidget) => {
    const sectionWidgets = widgets[oldWidget.section];
    let updatedWidgets = [];
    if (oldWidget.type === WidgetTypes.BLANK) {
      sectionWidgets[oldWidget.widgetConfig.index] = {
        ...newWidget,
        id: crypto.randomUUID(),
      };
      updatedWidgets = [...sectionWidgets];
    } else {
      updatedWidgets = [...sectionWidgets].map((wg) =>
        wg.widgetConfig.heading === oldWidget.widgetConfig.heading
          ? { ...newWidget, id: crypto.randomUUID() }
          : { ...wg }
      );
    }
    // since we are giving add/remove in direct report so we need to update report widgets right here
    const newWidgets = { ...widgets, [oldWidget.section]: updatedWidgets };
    setWidgets(newWidgets);
    setShowWidgetsLibrary(false);
    overflowing();
    await ReportService.updateReport(report?.reportId, {
      name: report.companyName,
      date: report.reportDate,
      type: ReportTypes.WorkingCapital,
      manualInput: {
        ...report,
        widgets: newWidgets,
        reportPages,
      },
    });
    setSuccessMessage('Report Saved');
  };

  const onDeleteWidget = async (oldWidget) => {
    const sectionWidgets = widgets[oldWidget.section];
    const updatedWidgets = [...sectionWidgets].map((wg, index) =>
      wg.widgetConfig.heading === oldWidget.widgetConfig.heading
        ? {
            action: { onAdd: () => setShowWidgetsLibrary(true) },
            widgetConfig: {
              index,
            },
            type: WidgetTypes.BLANK,
          }
        : { ...wg }
    );
    // since we are giving add/remove in direct report so we need to update report widgets right here
    const newWidgets = { ...widgets, [oldWidget.section]: updatedWidgets };
    setWidgets(newWidgets);
    await ReportService.updateReport(report?.reportId, {
      name: report.companyName,
      date: report.reportDate,
      type: ReportTypes.WorkingCapital,
      manualInput: {
        ...report,
        widgets: newWidgets,
        reportPages,
      },
    });
    setSuccessMessage('Report Saved');
  };

  const qrCodeExternalLink = getVideoUrlWithTenant(tenant, UWCData.videoId);

  return (
    <>
      <WidgetsLibraryModal />
      <AlertWrapper className="alert-position">
        <Alert
          color="info"
          message={successMessage}
          setMessage={setSuccessMessage}
          time={8000}
        />
        <Alert
          color="danger"
          message={errorMessage}
          setMessage={setErrorMessage}
          time={8000}
        />
      </AlertWrapper>
      <GenerateWorkingCapitalReportModal
        report={report}
        organization={organization}
        setReport={setReport}
        openGenerateReport={openGenerateReport}
        setOpenGenerateReport={setOpenGenerateReport}
        handleGenerateReport={handleGenerateReport}
        selectedReport={selectedRpt}
        isEdited={isEdited}
        insightsData={insightsData}
        reportPages={reportPages}
        widgets={widgets}
        selectedTenant={selectedTenant}
      />
      {pastReports.length === 0 &&
        readOnly &&
        !loadingReport &&
        !loadingPastReports && (
          <NoDataFound
            icon={NO_REPORTS_AVAILABLE_ICON}
            iconRounded={true}
            containerStyle="my-6 py-6"
            title={NO_REPORTS_AVAILABLE}
          />
        )}

      <ReportAction
        readOnly={readOnly}
        pastReports={pastReports}
        handleManualReport={handleManualReport}
        rptGenerated={rptGenerated}
        organization={organization}
        handleEditReport={handleEditReport}
        loadingReport={loadingReport}
        loadingPastReports={loadingPastReports}
        selectedRpt={selectedRpt}
        setSelectedRpt={(newReport) => {
          updateHash('');
          setSelectedRpt(newReport);
        }}
        profileInfo={profileInfo}
        report={report}
        startDownload={startDownload}
        setStartDownload={setStartDownload}
        reportType={ReportTypes.WorkingCapital}
        downloadOptions={DOWNLOAD_OPTIONS}
        linkConfig={{
          area: {
            areaX: 520,
            areaY: 730,
            areaWidth: 110,
            areaHeight: 30,
          },
          page: reportPages[WorkingCapitalReportSections.LoanOverview]?.enabled
            ? 3
            : 2,
          url: qrCodeExternalLink,
        }}
      />
      <div className="text-center">
        {!rptGenerated &&
          !loadingReport &&
          !readOnly &&
          !loadingPastReports && (
            <ReportDragDrop
              file={null}
              setFile={() => {}}
              loader={false}
              onRemoveFile={() => {}}
              onLoadFile={() => {}}
              handleGenerate={handleGenerateManuallyReport}
              uploadIcon="edit_document"
              fileUpload="Enter data to generate Working Capital report."
            />
          )}
      </div>

      {loadingReport || loadingPastReports ? (
        <ReportBlocksSkeleton />
      ) : (
        <>
          {rptGenerated && (
            <>
              {startDownload && (
                <ReportPDFWrapper>
                  <ReportCover
                    name={report.companyName}
                    date={report.reportDate}
                    excelBankMode={isExcelBank}
                    selectedTenant={selectedTenant}
                    report={report}
                    type={ReportTypes.WorkingCapital}
                    organization={organization}
                  />
                  <ReportPages
                    pages={widgets}
                    report={report}
                    showIcon={false}
                    reportPages={reportPages}
                    sectionKeys={WorkingCapitalReportSectionKeys}
                    pageConfig={PageConfig}
                    selectedTenant={selectedTenant}
                    reportType={ReportTypes.WorkingCapital}
                  />
                </ReportPDFWrapper>
              )}
              <div className={`pb-3 ${readOnly ? 'client-dashboard-rpt' : ''}`}>
                <ListGroup className="list-group-no-gutters working-capital-report mt-0 list-group-flush">
                  {WorkingCapitalReportSectionKeys.flatMap((key) => {
                    const widgetsForCurrentKey = widgets[key] || [];
                    const pageEnabled =
                      reportPages && reportPages[key]?.enabled;
                    const widgetsWithoutBlank = widgetsForCurrentKey
                      ?.filter((widget) => widget.type !== WidgetTypes.BLANK)
                      .map((wg) => ({
                        ...wg,
                        id: crypto.randomUUID(),
                        section: key,
                      }));

                    if (
                      !isExcelBank &&
                      widgetsWithoutBlank.length &&
                      pageEnabled
                    ) {
                      return widgetsWithoutBlank?.map((widget) => (
                        <WidgetWithActionButtons
                          key={widget.id}
                          widget={widget}
                          section={key}
                          onAddWidget={onAddWidget}
                          onDeleteWidget={onDeleteWidget}
                          reportType={ReportTypes.WorkingCapital}
                          selectedTenant={selectedTenant}
                          setSelectedWidget={setSelectedWidget}
                          setShowWidgetsLibrary={setShowWidgetsLibrary}
                          actionButtons={{}}
                        />
                      ));
                    }

                    if (isExcelBank && pageEnabled) {
                      return widgetsWithoutBlank?.map((widget) => (
                        <WidgetWithActionButtons
                          key={widget.id}
                          widget={widget}
                          section={key}
                          onAddWidget={onAddWidget}
                          onDeleteWidget={onDeleteWidget}
                          reportType={ReportTypes.WorkingCapital}
                          selectedTenant={selectedTenant}
                          setSelectedWidget={setSelectedWidget}
                          setShowWidgetsLibrary={setShowWidgetsLibrary}
                          actionButtons={{}}
                        />
                      ));
                    }
                    return null;
                  })}
                  <DisclaimerWidget />
                </ListGroup>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default WorkingCapitalAnalysisReport;
