import React, { useEffect, useState } from 'react';
import auditService from '../../services/audit.service';
import moment from 'moment';
import { TransitionGroup } from 'react-transition-group';
import MaterialIcon from '../commons/MaterialIcon';
import Skeleton from 'react-loading-skeleton';
import ButtonIcon from '../commons/ButtonIcon';
import Collapse from '@mui/material/Collapse';
import { DATE_FORMAT, DATE_FORMAT_TIME, capitalize } from '../../utils/Utils';
import NoDataFound from '../commons/NoDataFound';
import userService from '../../services/user.service';
import contactService from '../../services/contact.service';
import { useParams } from 'react-router-dom';
import { usePagesContext } from '../../contexts/pagesContext';

const AuditActivityTimeline = ({
  resourceId,
  parentResourceId,
  extraFilters = {},
  checklist,
  organization,
  timelineExport,
  parentAudit,
  customClass,
  closeSidePanel = () => {},
  refresh,
}) => {
  const [auditLogs, setAuditLogs] = useState([]);
  const [auditData, setAuditData] = useState([]);
  const [allAudits, setAllAudits] = useState([]);
  const LostWon = {
    'Closed Won': {
      label: (
        <div className="d-inline-flex text-success align-items-center gap-1">
          Won
          <MaterialIcon icon="thumb_up" clazz="text-success" size="fs-6" />
        </div>
      ),
      status: ' status ',
    },
    'Closed Lost': {
      label: (
        <div className="d-inline-flex text-red align-items-center gap-1">
          Lost
          <MaterialIcon icon="thumb_down" clazz="text-red" size="fs-6" />
        </div>
      ),
      status: ' status ',
    },
  };
  const [loading, setLoading] = useState(false);
  const { pageContext } = usePagesContext();
  const [csvLoading, setCSVLoading] = useState(false);
  const { organizationId } = useParams();
  const [feedPagination, setFeedPagination] = useState({ page: 1, limit: 10 });
  const [paginationLoader, setPaginationLoader] = useState(false);

  const mergeAudits = (setOne, setTwo) => {
    const combinedSet = [...setOne];
    for (let i = 0; i < setTwo.length; i++) {
      const date = setTwo[i].date;
      const matchingIndex = combinedSet.findIndex((data) => data.date === date);
      if (matchingIndex !== -1) {
        combinedSet[matchingIndex].logs = [
          ...combinedSet[matchingIndex].logs,
          ...setTwo[i].logs,
        ];
      } else {
        combinedSet.push(setTwo[i]);
      }
    }
    return combinedSet;
  };

  const fetchAudits = async () => {
    if (!paginationLoader) {
      setLoading(true);
    }
    try {
      const params = {
        resources: [
          {
            resourceType: `ne 'external'`,
          },
        ],
      };
      if (resourceId !== '') {
        params.resourceId = resourceId;
      }
      if (parentResourceId !== '') {
        params.parentResourceId = parentResourceId;
      }
      Object.assign(params, extraFilters);
      const { data, pagination } = await auditService.getAudits(
        params,
        feedPagination
      );
      setAuditData(data);
      // Filter out items with resourceType
      const filteredData = data.filter(
        (entry) =>
          !(
            entry.resourceType === 'organizationChecklistItemProgress' &&
            entry.action === 'create'
          ) &&
          !(
            entry.resourceType === 'organizationChecklistProgress' &&
            entry.action === 'create'
          ) &&
          !(
            entry.resourceType === 'organizationChecklistItemProgress' &&
            entry.action === 'update'
          )
      );

      // Grouping feed data on createdAt
      const groupedData = filteredData.reduce((groups, item) => {
        const date = item.createdAt.split('T')[0];
        const existingGroup = groups.find((group) => group.date === date);
        if (existingGroup) {
          existingGroup.logs.push(item);
        } else {
          groups.push({ date, logs: [item] });
        }
        return groups;
      }, []);

      if (paginationLoader) {
        const mergedLogs = mergeAudits(auditLogs, groupedData);
        setAuditLogs(mergedLogs);
      } else {
        setAuditLogs(groupedData);
      }
      setFeedPagination(pagination);
    } catch (error) {
      console.error('Error fetching audits:', error);
    } finally {
      setLoading(false);
      setPaginationLoader(false);
    }
  };

  const fetchAuditsWithAssociations = async () => {
    if (!paginationLoader) {
      setLoading(true);
    }
    try {
      const params = {
        resources: [
          {
            resourceType: `ne 'external'`,
          },
        ],
      };
      if (resourceId !== '') {
        params.resourceId = resourceId;
      }
      Object.assign(params, extraFilters);
      const { data, pagination } = await auditService.getAuditsWithAssociations(
        params,
        feedPagination
      );
      setAllAudits([...allAudits, ...data]);
      setAuditData(data);
      const filteredData = data.filter(
        (entry) =>
          !(
            entry.resourceType === 'organizationChecklistItemProgress' &&
            entry.action === 'create'
          ) &&
          !(
            entry.resourceType === 'organizationChecklistProgress' &&
            entry.action === 'create'
          ) &&
          !(
            entry.resourceType === 'organizationChecklistItemProgress' &&
            entry.action === 'update'
          )
      );

      // Grouping feed data on createdAt
      const groupedData = filteredData.reduce((groups, item) => {
        const date = item.createdAt.split('T')[0];
        const existingGroup = groups.find((group) => group.date === date);
        if (existingGroup) {
          existingGroup.logs.push(item);
        } else {
          groups.push({ date, logs: [item] });
        }
        return groups;
      }, []);

      if (paginationLoader) {
        const mergedLogs = mergeAudits(auditLogs, groupedData);
        setAuditLogs(mergedLogs);
      } else {
        setAuditLogs(groupedData);
      }
      setFeedPagination(pagination);
    } catch (error) {
      console.error('Error fetching audits:', error);
    } finally {
      setLoading(false);
      setPaginationLoader(false);
    }
  };

  useEffect(() => {
    if (parentAudit) {
      fetchAudits();
    } else {
      fetchAuditsWithAssociations();
    }
  }, [
    feedPagination?.page,
    organizationId,
    refresh,
    pageContext?.RefreshTimeline,
  ]);

  const AuditResourceTypes = {
    activity: {
      name: 'activity',
      getIcon: (audit) => {
        return audit.changeLog?.meta?.type === 'call'
          ? 'phone'
          : audit.changeLog?.meta?.type === 'task'
          ? 'task'
          : audit.changeLog?.meta?.type === 'event'
          ? 'event'
          : 'phone';
      },
    },
    activityRequest: { name: 'activity request', icon: 'mail' },
    activityOwner: { name: 'activity owner', icon: 'person' },
    assignment: { name: 'assignment', icon: 'Assignment' },
    checklist: { name: 'checklist', icon: 'Checklist' },
    checklistItem: { name: 'checklist item', icon: 'Checklist' },
    contact: { name: 'contact', icon: 'person' },
    contactFollower: { name: 'contact follower', icon: 'person' },
    contactOwner: { name: 'Owner', icon: 'person' },
    course: { name: 'course', icon: 'book_2' },
    deal: { name: 'opportunity', icon: 'monetization_on' },
    dealFollower: { name: 'opportunity follower', icon: 'person' },
    dealOwner: { name: 'owner', icon: 'person' },
    field: { name: 'field', icon: 'text_fields' },
    file: { name: 'file', icon: 'attachment' },
    pipelineTeam: {
      name: 'Team',
      icon: 'groups',
    },
    userNotificationSetting: {
      name: 'Notification',
      icon: 'notifications',
    },
    pipelineStage: {
      name: '',
      icon: 'view_carousel',
    },
    pipeline: {
      name: 'Pipeline',
      icon: 'view_carousel',
    },
    note: { name: 'note', icon: 'insert_comment' },
    organization: { name: 'organization', icon: 'corporate_fare' },
    organizationChecklist: {
      name: 'checklist',
      icon: 'Checklist',
    },
    organizationChecklistContact: {
      name: 'Organization Checklist Contact',
      icon: 'person',
    },
    organizationFollower: { name: 'Organization Follower', icon: 'person' },
    organizationOwner: { name: 'owner', icon: 'person' },
    organizationReport: { name: 'Organization Report', icon: 'Report' },
    product: { name: 'product', icon: 'Inventory' },
    report: { name: 'report', icon: 'Report' },
    team: { name: 'team', icon: 'groups' },
    teamMember: { name: 'teamMember', icon: 'person' },
    user: { name: 'user', icon: 'person' },
    comment: { name: 'comment', icon: 'insert_comment' },
    category: { name: 'category', icon: 'Category' },
    organizationChecklistProgress: {
      name: 'Organization Checklist Progress',
      icon: 'person_raised_hand',
    },
    organizationChecklistItemProgress: {
      name: 'Organization Checklist Item Progress',
      icon: 'gesture_select',
    },
    organizationChecklistOwner: {
      name: 'owner',
      icon: 'person',
    },
    organizationChecklistFile: {
      name: 'file',
      icon: 'upload_file',
    },
    dealProduct: {
      name: 'opportunity product',
      icon: 'shopping_cart',
    },
    stage: {
      name: 'stage',
      icon: 'stacked_bar_chart',
    },
    group: {
      name: 'role',
      icon: 'account_tree',
    },
    role: {
      name: 'role',
      icon: 'account_tree',
    },
    lesson: {
      name: 'Lesson',
      icon: 'school',
    },
  };

  const TimelineAction = {
    userNotificationSetting: 'userNotificationSetting',
    pipeline: 'pipeline',
  };

  const TimelineHead = ({ createdAt }) => {
    return (
      <div className="timeline-head font-weight-medium">
        {moment(createdAt).format('MMM DD, YYYY')}{' '}
      </div>
    );
  };
  const findObjectsByType = (data, targetType) => {
    return data.filter((obj) => obj.resourceType === targetType);
  };
  const ChangeLog = ({ item }) => {
    const { auditAssociations } = item;

    const firstPart = auditAssociations?.filter((asc) => asc.type !== 'parent');
    const secondPart = auditAssociations?.filter(
      (asc) => asc.type === 'parent'
    );

    const splitActorName = item?.actorDisplayValue
      ? item.actorDisplayValue.split(' ')
      : [];
    const actorName =
      splitActorName.length > 1
        ? capitalize(splitActorName[0]) + ' ' + capitalize(splitActorName[1])
        : '';

    const actorMessage = (
      <>
        <span className="text-black font-weight-semi-bold">{actorName}</span>{' '}
        {item.action + 'd'}{' '}
        {item?.changeLog?.update?.avatar
          ? ` a ${item?.changeLog?.update?.avatar?.displayValue} in `
          : ' a '}
        {item.resourceType && item.resourceType !== undefined
          ? AuditResourceTypes[item?.resourceType]?.name
          : ''}
      </>
    );

    const associationMsg = firstPart
      .reduce((acc, curr) => {
        const action = curr.type;
        const name =
          curr.resourceDisplayValue !== null &&
          item.resourceDisplayValue !== 'null null'
            ? curr.resourceDisplayValue
            : '';
        acc.push(`${action} ${name}`);
        return acc;
      }, [])
      .join(', ');
    const sortArray =
      item?.resourceType === 'pipelineTeam'
        ? secondPart?.sort((a, b) => {
            if (a.resourceType === 'team' && b.resourceType !== 'team') {
              return -1;
            }
            if (a.resourceType !== 'team' && b.resourceType === 'team') {
              return 1;
            }
            return 0;
          })
        : secondPart;

    let associationResources = '';
    associationResources = sortArray?.map((item, index, array) => {
      return (
        <span key={item?.auditAssociationId}>
          {index === 0 ? '' : item?.resourceType}{' '}
          <strong>
            {item.resourceDisplayValue !== null &&
            item.resourceDisplayValue !== 'null null'
              ? item?.resourceDisplayValue
              : ''}
          </strong>
          {index === array?.length - 1 ? '' : ' in this '}
        </span>
      );
    });

    const checklistItem = findObjectsByType(auditAssociations, 'checklistItem');
    const checklist = findObjectsByType(auditAssociations, 'checklist');
    const organization = findObjectsByType(auditAssociations, 'organization');
    const contact = findObjectsByType(auditAssociations, 'contact');
    const organizationChecklist = findObjectsByType(
      auditAssociations,
      'organizationChecklist'
    );

    return (
      <>
        {item.resourceType === 'organizationChecklistItemProgress' &&
        item.action === 'complete' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      <span className="text-black font-weight-semi-bold">
                        {actorName}
                      </span>{' '}
                      completed {checklistItem[0]?.resourceDisplayValue} on
                      checklist {checklist[0]?.resourceDisplayValue} in company{' '}
                      <span className="text-black font-weight-semi-bold">
                        {organization[0]?.resourceDisplayValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : item.resourceType === 'organizationChecklistContact' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <MaterialIcon
                    symbols={true}
                    icon={AuditResourceTypes[item.resourceType]?.icon}
                  />
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      <span className="text-black font-weight-semi-bold">
                        {actorName}
                      </span>{' '}
                      {item.action === 'delete' ? 'Unassigned' : 'Assigned'}{' '}
                      {contact[0]?.resourceDisplayValue}{' '}
                      {item.action === 'delete' ? 'from' : 'to'}{' '}
                      {checklist[0]?.resourceDisplayValue} in company{' '}
                      <span className="text-black font-weight-semi-bold">
                        {organization[0]?.resourceDisplayValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : item.resourceType === 'organizationChecklist' &&
          item.action === 'delete' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <MaterialIcon symbols={true} icon={'playlist_remove'} />
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      <span className="text-black font-weight-semi-bold">
                        {actorName}
                      </span>{' '}
                      {item.action + 'd'} a {checklist[0].type}{' '}
                      {checklist[0].resourceDisplayValue} from company{' '}
                      <span className="text-black font-weight-semi-bold">
                        {organization[0]?.resourceDisplayValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : item.resourceType === 'organizationChecklist' &&
          item.action === 'create' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <MaterialIcon symbols={true} icon={'playlist_add'} />
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      <span className="text-black font-weight-semi-bold">
                        {actorName}
                      </span>{' '}
                      {item.action + 'd'} a checklist{' '}
                      {checklist[0].resourceDisplayValue} in company{' '}
                      <span className="text-black font-weight-semi-bold">
                        {organization[0]?.resourceDisplayValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : item.resourceType === 'organizationChecklistOwner' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <MaterialIcon
                    symbols={true}
                    icon={AuditResourceTypes[item.resourceType]?.icon}
                  />
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      <span className="text-black font-weight-semi-bold">
                        {actorName}
                      </span>{' '}
                      {item.action + 'd'} an owner on Checklist{' '}
                      {checklist[0].resourceDisplayValue} in company{' '}
                      <span className="text-black font-weight-semi-bold">
                        {organization[0]?.resourceDisplayValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : item.resourceType === 'organizationChecklistFile' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <MaterialIcon
                    symbols={true}
                    icon={AuditResourceTypes[item.resourceType]?.icon}
                  />
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      <span className="text-black font-weight-semi-bold">
                        {actorName}
                      </span>{' '}
                      uploaded a file in Checklist{' '}
                      {checklist[0].resourceDisplayValue} of company{' '}
                      <span className="text-black font-weight-semi-bold">
                        {organization[0]?.resourceDisplayValue}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : item.resourceType === 'note' &&
          item.resourceDisplayValue === 'note mention' ? (
          <div className="timeline-section">
            <div className="timeline-item">
              <span className="timeline-item-time text-muted">
                {moment(item.createdAt).format('h:mm A')}
              </span>
              <div className="timeline-item-detail">
                <div className="d-flex align-items-center position-relative gap-2">
                  <MaterialIcon
                    symbols={true}
                    icon={AuditResourceTypes[item.resourceType]?.icon}
                  />
                  <div className="timeline-item-description font-size-sm">
                    <div className="text-wrap">
                      {actorMessage} and {associationMsg} on{' '}
                      <span className="text-black font-weight-semi-bold">
                        {associationResources}
                      </span>{' '}
                      {organizationChecklist[0]?.resourceDisplayValue}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            {item.resourceDisplayValue !== 'note direct mention' &&
            item.resourceType !== 'checklistItem' &&
            item.resourceType !== 'organizationChecklistProgress' &&
            !item?.changeLog?.update?.tenant_deal_stage_id &&
            !item?.changeLog?.update?.status &&
            item?.resourceType !== 'dealProduct' ? (
              <div className="timeline-section">
                <div className="timeline-item">
                  <span className="timeline-item-time text-muted">
                    {moment(item.createdAt).format('h:mm A')}
                  </span>
                  <div className="timeline-item-detail">
                    <div className="d-flex align-items-center position-relative gap-2">
                      <MaterialIcon
                        symbols={true}
                        icon={
                          AuditResourceTypes[item.resourceType]?.getIcon
                            ? AuditResourceTypes[item.resourceType]?.getIcon(
                                item
                              )
                            : AuditResourceTypes[item.resourceType]?.icon
                        }
                      />
                      <div className="timeline-item-description font-size-sm">
                        <div className="text-wrap">
                          {actorMessage} {associationMsg}{' '}
                          {item?.resourceType === 'pipelineTeam' ? (
                            ''
                          ) : (
                            <span className="text-black fw-bold">
                              {item?.resourceType === 'fileAssociation'
                                ? 'file'
                                : item.resourceDisplayValue !== null &&
                                  item.resourceDisplayValue !== 'null null'
                                ? item.resourceDisplayValue
                                : ''}
                            </span>
                          )}
                          {item.resourceType === 'deal' ? (
                            ''
                          ) : (
                            <>
                              {item?.resourceType === 'pipelineTeam'
                                ? ''
                                : ' in '}{' '}
                              <span className="text-black">
                                {associationResources}
                              </span>{' '}
                              {organizationChecklist[0]?.resourceDisplayValue}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {item?.changeLog?.update &&
                typeof item?.changeLog?.update === 'object' &&
                Object.keys(item?.changeLog?.update)?.length > 0 &&
                item?.resourceType !== 'dealProduct' ? (
                  <div className="timeline-section">
                    <div className="timeline-item">
                      <span className="timeline-item-time text-muted">
                        {moment(item.createdAt).format('h:mm A')}
                      </span>
                      <div className="timeline-item-detail">
                        <div className="d-flex align-items-center position-relative gap-2">
                          <MaterialIcon
                            symbols={true}
                            icon={
                              AuditResourceTypes[item.resourceType]?.getIcon
                                ? AuditResourceTypes[
                                    item.resourceType
                                  ]?.getIcon(item)
                                : AuditResourceTypes[item.resourceType]?.icon
                            }
                          />
                          <div className="timeline-item-description font-size-sm">
                            {item?.changeLog?.update?.tenant_deal_stage_id ? (
                              <div className="text-wrap">
                                <b>{actorName}</b> {item?.action}d{' '}
                                <b>{item.resourceDisplayValue}</b>
                                {LostWon[
                                  item?.changeLog?.update?.tenant_deal_stage_id
                                    ?.toDisplayValue
                                ]?.status || ' stage '}
                                {' to '}
                                <b>
                                  {LostWon[
                                    item?.changeLog?.update
                                      ?.tenant_deal_stage_id?.toDisplayValue
                                  ]?.label ||
                                    item?.changeLog?.update
                                      ?.tenant_deal_stage_id?.toDisplayValue}
                                </b>
                              </div>
                            ) : item?.changeLog?.update?.status ? (
                              <div className="text-wrap">
                                <b>{actorName}</b> {item?.action}d{' '}
                                {item?.changeLog?.update?.status?.displayValue}
                                {' deal '}
                                <b>
                                  {
                                    item?.changeLog?.update?.status
                                      ?.toDisplayValue
                                  }
                                </b>
                                {' on '}
                                <span className="text-black font-weight-semi-bold">
                                  {associationResources}
                                </span>{' '}
                                {organizationChecklist[0]?.displayValue}
                              </div>
                            ) : (
                              ''
                            )}
                            {!item?.changeLog?.update?.tenant_deal_stage_id &&
                              !item?.changeLog?.update?.status && (
                                <div className="text-muted text-break">
                                  {item.resourceDisplayValue !== null &&
                                  item.resourceDisplayValue !== 'null null'
                                    ? item.resourceDisplayValue
                                    : ''}{' '}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {item?.resourceType === 'dealProduct' ? (
                      <div className="timeline-section">
                        <div className="timeline-item">
                          <span className="timeline-item-time text-muted">
                            {moment(item.createdAt).format('h:mm A')}
                          </span>
                          <div className="timeline-item-detail">
                            <div className="d-flex align-items-center position-relative gap-2">
                              <MaterialIcon
                                symbols={true}
                                icon={
                                  AuditResourceTypes[item.resourceType]?.icon
                                }
                              />
                              <div className="timeline-item-description font-size-sm">
                                <div className="text-wrap">
                                  <b>{actorName}</b> {item?.action}d a deal
                                  product on{' '}
                                  <span className="text-black font-weight-semi-bold">
                                    {item.resourceDisplayValue !== null &&
                                    item.resourceDisplayValue !== 'null null'
                                      ? item.resourceDisplayValue
                                      : ''}
                                  </span>{' '}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const NotificationSettings = ({ actorName, item = {} }) => {
    const { from, to } =
      item?.changeLog?.update?.global || item?.changeLog?.update?.settings;

    const changes = Object.keys(from).reduce((acc, key) => {
      if (from[key] !== to[key]) {
        acc.push({
          field: key,
          from: from[key],
          to: to[key],
        });
      }
      return acc;
    }, []);

    return (
      <div className="timeline-section">
        <div className="timeline-item">
          <span className="timeline-item-time text-muted">
            {moment(item.createdAt).format('h:mm A')}
          </span>
          <div className="timeline-item-detail">
            <div className="d-flex align-items-center position-relative gap-2">
              <MaterialIcon
                symbols={true}
                icon={AuditResourceTypes[item.resourceType]?.icon}
              />
              <div className="timeline-item-description font-size-sm">
                <div className="text-wrap">
                  <span className="text-black font-weight-semi-bold">
                    {actorName}
                  </span>{' '}
                  {changes?.length > 0 ? (
                    changes?.map((change, index) => (
                      <>
                        <b>
                          {String(
                            change.to === false
                              ? 'disabled'
                              : change.to === true
                              ? 'enabled'
                              : ''
                          )}
                        </b>{' '}
                        {change.field}
                        {index === changes?.length - 2
                          ? ' and '
                          : index === changes?.length - 1
                          ? ' in '
                          : ', '}
                      </>
                    ))
                  ) : (
                    <>{item.action}d </>
                  )}
                  {AuditResourceTypes[item.resourceType]?.name}
                </div>
                <div className="text-muted text-break">
                  {item.resourceDisplayValue !== null &&
                  item.resourceDisplayValue !== 'null null'
                    ? item.resourceDisplayValue
                    : item.resourceDisplayValue}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const TimelineItem = ({ item }) => {
    const splitActorName = item?.actorDisplayValue
      ? item.actorDisplayValue.split(' ')
      : [];
    const actorName =
      splitActorName.length > 1
        ? capitalize(splitActorName[0]) + ' ' + capitalize(splitActorName[1])
        : '';
    return (
      <>
        {item?.auditAssociations !== null &&
        item?.auditAssociations?.length > 0 ? (
          <ChangeLog item={item} />
        ) : (
          <>
            {item.resourceType === 'fileAssociation' &&
            item.action === 'create' ? (
              <div className="timeline-section">
                <div className="timeline-item">
                  <span className="timeline-item-time text-muted">
                    {moment(item.createdAt).format('h:mm A')}
                  </span>
                  <div className="timeline-item-detail">
                    <div className="d-flex align-items-center position-relative gap-2">
                      <MaterialIcon
                        symbols={true}
                        icon={AuditResourceTypes[item.resourceType]?.icon}
                      />
                      <div className="timeline-item-description font-size-sm">
                        <div className="text-wrap">
                          <span className="text-black font-weight-semi-bold">
                            {actorName}
                          </span>{' '}
                          upload a {AuditResourceTypes[item.resourceType]?.name}
                        </div>
                        <div className="text-muted text-break">
                          {item.resourceDisplayValue !== null &&
                          item.resourceDisplayValue !== 'null null'
                            ? item.resourceDisplayValue === 'blob'
                              ? ''
                              : item.resourceDisplayValue !== null &&
                                item.resourceDisplayValue !== 'null null'
                            : item.resourceDisplayValue}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {typeof item?.changeLog?.update?.status === 'object' ? (
                  <div className="timeline-section">
                    <div className="timeline-item">
                      <span className="timeline-item-time text-muted">
                        {moment(item.createdAt).format('h:mm A')}
                      </span>
                      <div className="timeline-item-detail">
                        <div className="d-flex align-items-center position-relative gap-2">
                          <MaterialIcon
                            symbols={true}
                            icon={AuditResourceTypes[item.resourceType]?.icon}
                          />
                          <div className="timeline-item-description font-size-sm">
                            <div className="text-wrap">
                              <b>{actorName}</b>{' '}
                              {item?.changeLog?.update?.status?.displayValue}
                              {' changed '}
                              {
                                item?.changeLog?.update?.status
                                  ?.fromDisplayValue
                              }
                              {' to '}
                              {item?.changeLog?.update?.status?.toDisplayValue}
                              {' user '}
                              <span className="text-black font-weight-semi-bold">
                                {item.resourceDisplayValue !== null &&
                                item.resourceDisplayValue !== 'null null'
                                  ? item.resourceDisplayValue
                                  : item.resourceDisplayValue}{' '}
                              </span>{' '}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : typeof item?.changeLog?.update?.isActive === 'object' ||
                  typeof item?.changeLog?.update?.isDefault === 'object' ? (
                  <div className="timeline-section">
                    <div className="timeline-item">
                      <span className="timeline-item-time text-muted">
                        {moment(item.createdAt).format('h:mm A')}
                      </span>
                      <div className="timeline-item-detail">
                        <div className="d-flex align-items-center position-relative gap-2">
                          <MaterialIcon
                            symbols={true}
                            icon={AuditResourceTypes[item.resourceType]?.icon}
                          />
                          <div className="timeline-item-description font-size-sm">
                            <div className="text-wrap">
                              <b>{actorName}</b>{' '}
                              {item?.changeLog?.update?.isActive
                                ?.toDisplayValue === false
                                ? 'deactivated'
                                : item?.changeLog?.update?.isActive
                                    ?.toDisplayValue === true
                                ? 'activated'
                                : ''}{' '}
                              {item?.changeLog?.update?.isDefault
                                ?.toDisplayValue === true ? (
                                <span>
                                  set{' '}
                                  <strong>
                                    {item.resourceDisplayValue !== null &&
                                    item.resourceDisplayValue !== 'null null'
                                      ? item.resourceDisplayValue
                                      : item.resourceDisplayValue}{' '}
                                    {
                                      AuditResourceTypes[item.resourceType]
                                        ?.name
                                    }
                                  </strong>{' '}
                                  as default
                                </span>
                              ) : (
                                ''
                              )}{' '}
                              <span className="text-black font-weight-semi-bold">
                                {' '}
                              </span>{' '}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : typeof item?.changeLog?.update?.global === 'object' ? (
                  <div className="timeline-section">
                    <div className="timeline-item">
                      <span className="timeline-item-time text-muted">
                        {moment(item.createdAt).format('h:mm A')}
                      </span>
                      <div className="timeline-item-detail">
                        <div className="d-flex align-items-center position-relative gap-2">
                          <MaterialIcon
                            symbols={true}
                            icon={AuditResourceTypes[item.resourceType]?.icon}
                          />
                          <div className="timeline-item-description font-size-sm">
                            <div className="text-wrap">
                              <b>{actorName}</b>{' '}
                              {item?.changeLog?.update?.global
                                ?.toDisplayValue === false
                                ? 'disabled global in '
                                : item?.changeLog?.update?.global
                                    ?.toDisplayValue === true
                                ? 'enabled global in'
                                : ''}{' '}
                              <span className="text-black font-weight-semi-bold">
                                {item.resourceDisplayValue !== null &&
                                item.resourceDisplayValue !== 'null null'
                                  ? item.resourceDisplayValue
                                  : item.resourceDisplayValue}{' '}
                              </span>
                              {' in Opportunity & Stages '}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {TimelineAction[item?.resourceType] &&
                    typeof item?.changeLog?.update?.settings === 'object' ? (
                      <NotificationSettings actorName={actorName} item={item} />
                    ) : (
                      <>
                        {item?.resourceType === 'course' ? (
                          <div className="timeline-section">
                            <div className="timeline-item">
                              <span className="timeline-item-time text-muted">
                                {moment(item.createdAt).format('h:mm A')}
                              </span>
                              <div className="timeline-item-detail">
                                <div className="d-flex align-items-center position-relative gap-2">
                                  <MaterialIcon
                                    symbols={true}
                                    icon={
                                      AuditResourceTypes[item.resourceType]
                                        ?.icon
                                    }
                                  />
                                  <div className="timeline-item-description font-size-sm">
                                    <div className="text-wrap">
                                      <span className="text-black font-weight-semi-bold">
                                        {actorName}
                                      </span>{' '}
                                      {item.action}d{' '}
                                      {item?.changeLog?.update?.status
                                        ? `the ${item?.changeLog?.update?.status?.displayValue} changed ${item?.changeLog?.update?.status?.fromDisplayValue} to ${item?.changeLog?.update?.status?.toDisplayValue} `
                                        : ''}
                                      {
                                        AuditResourceTypes[item.resourceType]
                                          ?.name
                                      }
                                    </div>
                                    <div className="text-muted text-break">
                                      {item.resourceDisplayValue !== null &&
                                      item.resourceDisplayValue !== 'null null'
                                        ? item.resourceDisplayValue
                                        : item.resourceDisplayValue}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="timeline-section">
                            <div className="timeline-item">
                              <span className="timeline-item-time text-muted">
                                {moment(item.createdAt).format('h:mm A')}
                              </span>
                              <div className="timeline-item-detail">
                                <div className="d-flex align-items-center position-relative gap-2">
                                  <MaterialIcon
                                    symbols={true}
                                    icon={
                                      AuditResourceTypes[item.resourceType]
                                        ?.icon
                                    }
                                  />
                                  <div className="timeline-item-description font-size-sm">
                                    <div className="text-wrap">
                                      <span className="text-black font-weight-semi-bold">
                                        {actorName}
                                      </span>{' '}
                                      {typeof item?.changeLog?.update
                                        ?.is_customer === 'object'
                                        ? `${
                                            item?.changeLog?.update?.is_customer
                                              ?.toDisplayValue === true
                                              ? 'Enabled'
                                              : 'Disabled'
                                          } a Customer Badge in `
                                        : `${item.action}d a `}
                                      {
                                        AuditResourceTypes[item.resourceType]
                                          ?.name
                                      }
                                    </div>
                                    <div className="text-muted text-break">
                                      {item.resourceDisplayValue !== null &&
                                      item.resourceDisplayValue !== 'null null'
                                        ? item.resourceDisplayValue
                                        : ''}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const headers = [
    {
      key: 'Date / Time',
      value: 'createdAt',
    },
    {
      key: 'Name',
      value: 'actorDisplayValue',
    },
    {
      key: 'Email',
      value: 'email',
    },
    {
      key: 'Checklist Type',
      value: 'actorType',
    },
    {
      key: 'Checklist Item',
      value: 'checklistItem',
    },
    {
      key: 'Action Status',
      value: 'changeLog',
    },
  ];
  const exportDataToCSV = async (data, companyName, checklistName) => {
    setCSVLoading(true);
    try {
      const rowPromises = data.map(async (log) => {
        let contactData = {};
        if (log?.actorType === 'contact') {
          contactData = await contactService.getContactById(log?.actorId);
        } else {
          contactData = await userService.getUserById(log?.actorId);
        }
        const rowValues = headers.map((header) => {
          let value = log;

          if (header.value === 'actorType') {
            value = ['acknowledged', 'uploaded', 'signed', 'watched'].includes(
              log?.changeLog?.update?.status?.toDisplayValue
            )
              ? log[header.value] === 'user'
                ? 'Internal'
                : log[header.value] === 'contact'
                ? 'External'
                : '-'
              : '-';
          } else if (header.value === 'email') {
            value = contactData?.email || contactData?.email_home || '-';
          } else if (header.value === 'createdAt') {
            value =
              value && value[header.value]
                ? moment(value[header.value]).format(DATE_FORMAT_TIME)
                : '-';
          } else if (header.value === 'changeLog') {
            value =
              value &&
              ['acknowledged', 'uploaded', 'signed', 'watched'].includes(
                value?.changeLog?.update?.status?.toDisplayValue
              )
                ? 'Completed'
                : value?.resourceType === 'organizationChecklistFile' &&
                  value?.action === 'create'
                ? `File uploaded`
                : value?.resourceType === 'note'
                ? `Note ${value?.action}`
                : value?.resourceType === 'organizationChecklistContact'
                ? `Contact ${
                    value?.action === 'create' ? 'assigned' : 'unassigned'
                  }`
                : value?.resourceType === 'organizationChecklistFile' &&
                  value?.action === 'delete'
                ? 'File deleted'
                : value?.resourceType === 'organizationChecklistOwner' &&
                  value?.action === 'create'
                ? 'Owner Created'
                : '';
          } else if (header.value === 'checklistItem') {
            if (value && value?.auditAssociations?.length > 0) {
              const checklistResource = value?.auditAssociations?.find(
                (resource) => resource.resourceType === 'checklistItem'
              );
              value = checklistResource
                ? checklistResource.resourceDisplayValue
                : '-';
            } else {
              value = '-';
            }
          } else {
            const nestedKeys = header.value.split('.');
            nestedKeys.forEach((key) => {
              if (value && key in value) {
                value = value[key];
              } else {
                value = '-';
              }
            });
          }
          return value;
        });
        return rowValues.join(',');
      });

      // Filter out undefined values from rowPromises
      const filteredRowPromises = rowPromises.filter(
        (rowPromise) => rowPromise
      );

      // Ensure all promises resolve
      const csvRows = await Promise.all(filteredRowPromises);

      const companyTitle = [{ Title: 'Company Name', Value: `${companyName}` }];
      const checklistTitle = [
        { Title: 'Checklist Name', Value: `${checklistName}` },
      ];
      const csvContent =
        checklistTitle?.map((obj) => Object.values(obj).join(',')).join('\n') +
        '\n' +
        companyTitle?.map((obj) => Object.values(obj).join(',')).join('\n') +
        '\n' +
        '\n' +
        headers.map((header) => `${header.key}`).join(',') +
        '\n' +
        csvRows?.join('\n');

      // Create a Blob with the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

      const CurrentDate = moment(new Date()).format(DATE_FORMAT);

      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute(
        'download',
        `${companyName}-${checklistName}-${CurrentDate}.csv`
      );
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
    } catch (e) {
      console.log(e);
    } finally {
      setCSVLoading(false);
    }
  };

  return (
    <>
      <div className="overflow-hidden px-3 tab-item">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className={`mb-3 ${customClass}`}>History</h4>
          {timelineExport && (
            <ButtonIcon
              label="Export"
              onclick={() =>
                exportDataToCSV(allAudits, organization?.name, checklist?.title)
              }
              loading={csvLoading}
              color="outline-primary"
              classnames="btn-sm mb-2"
            />
          )}
        </div>
        {loading ? (
          <div>
            <Skeleton count={3} height={10} className={'mb-2'} />
          </div>
        ) : (
          <>
            <div className="timeline-wrapper mb-4">
              <ul className="timeline-list">
                {auditLogs.map((group, index) => (
                  <div className="timeline-section" key={index}>
                    <TransitionGroup appear={true}>
                      <TimelineHead createdAt={group.date} />{' '}
                      {group.logs.map((item) => (
                        <Collapse key={item.auditId}>
                          <TimelineItem
                            item={item}
                            closeSidePanel={closeSidePanel}
                          />
                        </Collapse>
                      ))}
                    </TransitionGroup>
                  </div>
                ))}
              </ul>
              {auditData?.length > 0 &&
                auditData?.length < feedPagination?.count &&
                feedPagination?.totalPages > 1 && (
                  <ButtonIcon
                    classnames="view-more-btn button-pill -left-5 btn-pill btn-sm"
                    color="primary"
                    label="View more"
                    loading={paginationLoader}
                    onclick={() => {
                      setPaginationLoader(true);
                      setFeedPagination((prevState) => ({
                        ...prevState,
                        page: prevState.page + 1,
                      }));
                    }}
                  />
                )}
            </div>
          </>
        )}
        {!loading && parentResourceId && auditLogs.length === 0 && (
          <NoDataFound
            icon="edit_note"
            iconStyle="font-size-3em"
            containerStyle="text-gray-search my-1 py-1"
            title={'No timeline items'}
          />
        )}
      </div>
    </>
  );
};

export default AuditActivityTimeline;
