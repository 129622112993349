import userService from '../../services/user.service';
import { Dropdown, Tab, Tabs } from 'react-bootstrap';
// import AutoComplete from '../AutoComplete';
import React, { useState, useEffect } from 'react';
import MaterialIcon from './MaterialIcon';
import ButtonFilterDropdown from './ButtonFilterDropdown';
import { useProfileContext } from '../../contexts/profileContext';
import Avatar from '../Avatar';
import { STATUS_ACTIVE } from '../../utils/constants';
import IconTextLoader from '../loaders/IconText';
import { Input } from 'reactstrap';

const FilterTabsButtonDropdown = ({
  buttonText,
  options,
  filterTabs,
  filterOptionSelected,
  setFilterOptionSelected,
  handleFilterSelect,
  filterSelected,
  setFilterSelected,
  setFilterTabs,
  openFilter,
  setOpenFilter,
  onHandleFilterOwner,
  defaultSelection,
  extraClasses,
  setModified,
  selectedOwner,
  setSelectedOwner,
}) => {
  const filterKeys = options.map((k) => k.key);
  const { profileInfo } = useProfileContext();
  const [filterSearch, setFilterSearch] = useState({
    name: !filterKeys.includes(filterOptionSelected?.key)
      ? filterOptionSelected?.name || ''
      : '',
  });
  const [ownersData, setOwnersData] = useState([]);
  const [searchOwner, setSearchOwner] = useState();
  const [loading, setLoading] = useState(false);
  const getOwners = async () => {
    setLoading(true);
    const searchResults = await userService
      .getUsers(
        { ...{ search: searchOwner?.search, status: STATUS_ACTIVE } }, // only get active users
        {
          page: 1,
          limit: 3,
        }
      )
      .catch((err) => console.log(err));
    const { data } = searchResults || {};

    const filteredUsersData = data?.users?.map((item) => ({
      ...item,
      name: `${item.first_name} ${item.last_name}`,
    }));
    setOwnersData(filteredUsersData);
    setLoading(false);
  };

  const onInputSearch = (e) => {
    const { value } = e.target || {};
    setSearchOwner({
      search: value,
    });
  };

  const handleOwnerClick = (e, item) => {
    setOpenFilter(false);
    setSelectedOwner(item);
    onHandleFilterOwner(item);
    setFilterSearch(item);
  };

  const renderOwners = () => {
    if (loading) {
      return (
        <div className="px-3">
          <IconTextLoader count={3} />
        </div>
      );
    }
    if (ownersData?.length > 0) {
      return ownersData.map((item) => (
        <div
          key={item.id}
          onClick={(e) => handleOwnerClick(e, item)}
          className={`py-2 d-flex align-items-center gap-1 fs-7 bg-hover-gray text-black btn-outline-primary px-3 ${
            item.id === selectedOwner?.id ? 'bg-primary-soft' : ''
          }`}
        >
          <Avatar user={item} defaultSize="xs" />
          <span>{item.name}</span>
        </div>
      ));
    }
    return (
      <p className="mb-0 text-center w-100 p-2 text-muted">No data found.</p>
    );
  };

  useEffect(() => {
    if (filterTabs === 'owners') {
      getOwners();
    }
  }, [searchOwner?.search, filterTabs]);

  return (
    <ButtonFilterDropdown
      filterOptionSelected={filterOptionSelected}
      options={options}
      openFilter={openFilter}
      setOpenFilter={setOpenFilter}
      handleFilterSelect={handleFilterSelect}
      buttonText={buttonText}
    >
      <Dropdown.Menu
        className={`p-0 ${extraClasses} z-index-250`}
        style={{ minWidth: 320 }}
      >
        <Tabs
          fill
          justify
          id="controlled-tab-example"
          activeKey={filterTabs}
          onSelect={(k) => {
            setFilterTabs(k);
          }}
          className="mb-1 w-100 idf-tabs"
        >
          <Tab
            eventKey="filters"
            title={
              <span className="d-flex flex-column justify-content-center gap-1 align-items-center">
                <MaterialIcon icon="filter_list" />
                <span> Filters </span>
              </span>
            }
          >
            <div className="py-1 idf-dropdown-item-list">
              {options.map((option) => (
                <Dropdown.Item
                  key={option.id}
                  href="#"
                  onClick={(e) => handleFilterSelect(e, option)}
                  className="px-3"
                >
                  <div className="d-flex align-items-center justify-content-between py-1">
                    <span
                      className={
                        filterOptionSelected?.key === option.key
                          ? 'fw-bold'
                          : ''
                      }
                    >
                      {option.name}
                    </span>
                    {filterOptionSelected?.key === option.key && (
                      <MaterialIcon icon="check" clazz="fw-bold" />
                    )}
                  </div>
                </Dropdown.Item>
              ))}
            </div>
          </Tab>
          <Tab
            eventKey="owners"
            title={
              <span className="d-flex flex-column justify-content-center gap-1 align-items-center">
                <MaterialIcon icon="group" /> <span> Owners </span>
              </span>
            }
          >
            <div className="d-flex flex-column">
              <a
                onClick={(e) => {
                  onHandleFilterOwner({});
                  setFilterSearch({ name: '' });
                  setOpenFilter(false);
                  setFilterSelected({
                    ...filterSelected,
                    filter: { assigned_user_id: null },
                  });
                }}
                className="d-flex cursor-default bg-hover-gray btn-outline-primary py-2 px-3 gap-1 justify-content-between align-items-center"
              >
                <div className="d-flex align-items-center gap-1">
                  <MaterialIcon icon="people" clazz="pl-1" />
                  <span className="fs-7">Everyone</span>
                </div>
                {filterSearch?.name === '' && (
                  <MaterialIcon icon="check" clazz="text-primary" />
                )}
              </a>
              <a
                onClick={(e) => {
                  onHandleFilterOwner(profileInfo);
                  setFilterSearch(profileInfo);
                }}
                className="d-flex cursor-default bg-hover-gray btn-outline-primary py-2 px-3 gap-1 justify-content-between align-items-center"
              >
                <div className="d-flex align-items-center gap-1">
                  <Avatar user={profileInfo} defaultSize="xs" />
                  <span className="fs-7">
                    {profileInfo?.first_name} {profileInfo?.last_name} (you)
                  </span>
                </div>
                {filterSearch?.id === profileInfo?.id && (
                  <MaterialIcon icon="check" clazz="text-primary" />
                )}
              </a>
              <div className="pt-3 px-3 border-top position-relative">
                <Input
                  placeholder="Search owners"
                  value={searchOwner?.search}
                  onChange={onInputSearch}
                  onClick={(e) => e.stopPropagation()}
                />
                <MaterialIcon
                  icon="search"
                  clazz="position-absolute"
                  style={{ right: 25, top: 27 }}
                />
              </div>
              <div
                className="my-2 d-flex flex-column overflow-y-auto gap-1"
                style={{ maxHeight: 200 }}
              >
                {renderOwners()}
              </div>
            </div>
          </Tab>
        </Tabs>
      </Dropdown.Menu>
    </ButtonFilterDropdown>
  );
};

export default FilterTabsButtonDropdown;
